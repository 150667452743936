import React from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import CustomCard from '../components/generales/card'
import { MdNotifications } from 'react-icons/md'

const Notificaciones = () => {
  return (
    <Container fluid style={{marginTop: '-4rem', padding: '0 1rem'}}>
      <Row>
        <Col xs={12} sm={12} md={12}>
          <CustomCard titulo='Notificaciones'
            subtitulo='Lista de notificaciones recibidas'
            icono={<MdNotifications style={{fontSize:'1.5rem',marginRight:'4px'}}/>}
            detalle={<span> <strong>0</strong> notificacion(es) nueva(s)</span>}
          />
        </Col>
      </Row>
    </Container>
  )
}

export default Notificaciones
