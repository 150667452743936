import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import axios from "axios"
import {Apis} from "../util/Apis"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import {CustomProgressBar} from '../components/templates/cuponTemplate'
import NoElements from '../components/generales/noElements'
import LoadingCard from '../components/generales/loading'
import CustomCard from '../components/generales/card'
import { BsGift } from 'react-icons/bs'

const DescripcionRecompensa = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width:100%;
`
const TituloRecompensa = styled.span`
  font-size: 4rem;
  font-weight: bold;
`
const TipoRecompensa = styled.span`
  font-size: 1.4rem;
`
const ContenedorProgressBar = styled.div`
  width: 100%;
  margin-top: 1rem;
  text-align: center;

  span {
    font-size: 14px;
    font-weight: bold;
  }
`

const TablaHeader = styled.div`
  display: flex;
  width: 100%;
  font-weight: bold;
  text-align: center;
  border-block-end: 1px solid rgba(0, 0, 0, 0.133);
  padding: 0.3rem 0;
`
const NivelRecompensa = styled.div`
  display: flex;
  width: 100%;
  text-align: center;
  border-block-end: 1px solid rgba(0, 0, 0, 0.133);
  padding: 0.3rem 0;
  font-size: 14px;
  &:last-child{
    border-block-end: none;
  }
`
const Recompensas = () => {

  const userAuth = localStorage.getItem('auth')
  const salaInfo = JSON.parse(localStorage.getItem('salaInfo'))
  const [puntosSemanales, setPuntosSemanales] = useState(0)
  const [tablaRecompensas, setTablaRecompensas] = useState([])
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    generarRecompensas()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const config = {
    headers: {Authorization: `Bearer ${userAuth}`}
  };

  const generarRecompensas = () => {
    setLoading(true)
    axios.get(Apis.CAPP + '/rewards?salaId=' + salaInfo.salaId, config)
      .then(res => {
        setPuntosSemanales(res.data.weeklyPoints)
        setTablaRecompensas(res.data.rewards)
        setLoading(false)
      }).catch(err => {
      //console.log('Error: ' + err.message)

      setLoading(false)
    })
  }

  const pruebaB = [{
    min: 0,
    max: Math.min(...tablaRecompensas.map(item => item.min)) - 1,
    description: "0"
  }]

  const pruebaC = tablaRecompensas.concat(pruebaB);

  return (
    <>
      {loading ?
        <LoadingCard/>
        :
        <Container fluid style={{marginTop: '-4rem', padding: '0 1rem'}}>

          <Row>
            <Col xs={12} sm={12} md={12}>
              <CustomCard titulo='Recompensas'
                subtitulo='acumula puntos y gana grandes premios'
                icono={<BsGift style={{fontSize:'1.5rem',marginRight:'4px'}} />}
                detalle={<span> <strong>{puntosSemanales}</strong> punto(s) acumulado(s)</span>}
              />

              {tablaRecompensas.length > 0 ?
                <>
                  <CustomCard titulo='Recompensa actual'
                    cuerpo={
                      <DescripcionRecompensa>
                        <TituloRecompensa>
                        {pruebaC
                          .filter(item => (item.min <= puntosSemanales && item.max >= puntosSemanales))
                          .map(item => item.description)
                        }
                        </TituloRecompensa>
                        <TipoRecompensa>SOLES</TipoRecompensa>
                        <ContenedorProgressBar>
                          <CustomProgressBar style={{height: '2rem', fontSize: '16px', marginBottom: '1rem'}} animated
                            now={puntosSemanales}
                            label={puntosSemanales + ' puntos'}
                            min={
                              Math.max(...pruebaC.filter(item => (item.min <= puntosSemanales)).map(item => item.min))
                            }
                            max={
                              Math.min(...pruebaC.filter(item => item.max >= puntosSemanales).map(item => item.max))
                            }
                          />
                          <span>te faltan {Math.floor(Math.max(...pruebaC.filter(item => (item.min <= puntosSemanales)).map(item => item.max)) - puntosSemanales + 1)} punto(s)
                            para acceder a la siguiente recompensa
                          </span>
                        </ContenedorProgressBar>
                      </DescripcionRecompensa>
                    }
                  />
                  <CustomCard titulo='Tabla de recompensas' tipo2
                    icono={<BsGift style={{fontSize:'1.5rem',marginRight:'4px'}} />} 
                    subtitulo='lista de premios que puedes obtener'
                    cuerpo={
                      <div style={{marginTop:'1rem',width:'100%'}}>
                        <TablaHeader>
                          <div style={{width: '50%'}}>PUNTOS</div>
                          <div style={{width: '50%'}}>RECOMPENSA</div>
                        </TablaHeader>
                        {tablaRecompensas.map((item, i) =>
                          <NivelRecompensa key={i}>
                            <div style={{width: '50%'}}>
                              <span><strong>{item.min}</strong> a <strong>{item.max}</strong></span></div>
                            <div style={{width: '50%'}}>{item.description} SOLES</div>
                          </NivelRecompensa>
                        )}
                      </div>
                    }
                  />
                </>
                :
                <NoElements titulo='No hay recompensas disponibles en este momento'
                            subtitulo='Vuelva a intentarlo más tarde'/>
              }
            </Col>
          </Row>
        </Container>
      }
    </>
  )
}

export default Recompensas
