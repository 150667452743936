import React, {useContext, useEffect, useState} from 'react'
import {Navigate} from 'react-router-dom'
import axios from 'axios'
import {loginUser} from './actions/authentication.action'
import {Apis} from '../../util/Apis'
import AuthContext from './store/AuthContext'
import Form from 'react-bootstrap/Form'
import {
  CardLogin,
  ContenidoLogin,
  ContentCompany,
  ContentWargos,
  FormLabel,
  FormLogin,
  LoginContainer,
  NombreSala,
  SalaContent,
  SalaItem,
  SeleccioneSala,
  SelectSala,
  TextError
} from './loginElements'

import logofb from '../icons/facebook.svg'
import logowsp from '../icons/whatsapp.svg'
import logoinsta from '../icons/instagram.svg'
import {CustomButton2} from '../generales/customButton'
import {useLocation, useNavigate} from "react-router";
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs'

function useQuery() {
  const {search} = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Login = () => {
  const {stateUser, dispatch} = useContext(AuthContext)

  //const referer = '/home'
  const [referer, setReferer] = useState('/home')

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const navigate = useNavigate()
  const query = useQuery()

  const [checkPassword, setCheckPassword] = useState(false)
  const [selectCasino, setSelectCasino] = useState(false)
  const [sala, setSala] = useState({name: ' '})
  const [siteInfo, setSiteInfo] = useState(null)

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  useEffect(() => {
    getSalas()

    let checkSala = localStorage.getItem("salaInfo")
    if (checkSala !== undefined && checkSala !== null) {
      setSala(JSON.parse(checkSala))
      setSelectCasino(true)
    }
    const redirectTo = query.get('redirect')
    //console.log('redirectTo: ' + redirectTo)
    if (redirectTo && redirectTo !== '') {
      setReferer(redirectTo)
    }
  },[])

  const cambiarSala = () => {
    setSelectCasino(false)
    localStorage.removeItem("salaInfo")
  }

  function doLogin() {
    if (username === "" || password === "") {
      setError('Ingrese datos correctamente');
    } else {
      loginUser(username, password, sala.auth, dispatch, setError, setLoading);
    }
  }

  function getSalas() {
    axios.get(Apis.CAPP + '/site')
      .then(function (response) {
        setSiteInfo(response.data)
      })
      .catch(function (error) {
      })
  }

  function verifyUserAccount() {
    setError("")
    setLoading(true)
    if (username === "") {
      setError('Ingrese datos correctamente');
      setLoading(false)
      return
    }

    let params = {document: username, salaId: sala.salaId}
    axios.get(Apis.CAPP + '/verify-account', {params: params})
      .then(function (response) {
        setLoading(false)
        if (!response.data.exists) {
          setError("Acerquese al counter para afiliarse al club de jugadores")
          return
        }

        if (response.data.account) {
          setCheckPassword(true)
        } else {
          navigate("/sign-up/" + username)
        }
      })
      .catch(function () {
        setLoading(false)
        setError("Hubo un error")
      })
  }

  const isLoggedIn = stateUser.isAuthenticated

  if (isLoggedIn) {
    console.log('is logged in')
    return <Navigate to={referer}/>;
  }

  const handleLogin = (data, setSelectCasino, setSala) => {
    localStorage.setItem("salaInfo", JSON.stringify(data));
    setSelectCasino(true);
    setSala(data);
  }


  return (
    <LoginContainer>
      {
        siteInfo &&
        <CardLogin isSelect={selectCasino}>
          <ContenidoLogin>
            <div style={{padding:0, height:'120px', width:'120px', display:'flex', alignItems:'center', justifyContent:'center', background:'white'}}>
              <img src={siteInfo.logo} alt='logo' style={{height:'auto',width:'auto', maxHeight:'120px',maxWidth:'120px',borderRadius:'16px'}}/>
            </div>
            {selectCasino ?
              <>
                <div style={{display:'flex', flexDirection:'column',alignItems:'center'}}>
                  <NombreSala>{sala.name} </NombreSala>
                  <SelectSala onClick={cambiarSala}>cambiar sala</SelectSala>
                </div>
                <FormLogin>
                  <FormLabel id="labeldocument">Documento</FormLabel>
                  <Form.Control required autoFocus autoComplete="off"
                                type="tel"
                                id="document"
                                htmlFor="labeldocument"
                                value={username}
                                onChange={(e) =>
                                  setUsername(e.target.value)
                                }
                  />

                  {checkPassword ?
                    <>
                      <Form.Group style={{position:'relative'}}>
                        <FormLabel id="labelcontrasena">Contraseña</FormLabel>
                        <Form.Control required
                                      type={passwordShown ? "text" : "password"}
                                      id="contrasena"
                                      htmlFor="labelcontrasena"
                                      value={password}
                                      
                                      onChange={(e) =>
                                        setPassword(e.target.value)
                                      }
                        />
                        <div style={{position:'absolute',bottom:'8px',right:'12px'}}>
                          {passwordShown ? 
                            <BsEyeSlashFill color='#323232' onClick={togglePassword}/>
                            :
                            <BsEyeFill onClick={togglePassword}/>
                          }
                        </div>
                      </Form.Group>
                      
                      <CustomButton2
                        style={{marginTop: "1rem"}}
                        type="submit"
                        onClick={doLogin}
                        disabled={loading}
                      >
                        {loading ? 'CARGANDO' : 'INGRESAR'}
                      </CustomButton2>
                    </>
                    :
                    <>
                      <CustomButton2
                        style={{marginTop: "1rem"}}
                        type="submit"
                        onClick={verifyUserAccount}
                        disabled={loading}
                      >
                        {loading ? 'CARGANDO' : 'VALIDAR'}
                      </CustomButton2>
                    </>
                  }
                </FormLogin>

                <TextError>{error}</TextError>

                <ContentCompany>
                  <label>{siteInfo.name}</label>
                  <div>
                    <a href={sala.facebook} target="_blank" rel="noopener noreferrer">
                      <img src={logofb} alt="fb" width="27" height='27'/>
                    </a>
                    {sala.instagram != null &&
                      <a href={sala.instagram} target="_blank" rel="noopener noreferrer">
                        <img src={logoinsta} alt="inst" width="27" height='27'/>
                      </a>
                    }
                    <a href={sala.whatsapp} target="_blank" rel="noopener noreferrer">
                      <img src={logowsp} alt="wsp" width="27" height='27'/>
                    </a>
                  </div>
                </ContentCompany>
                <ContentWargos>
                  <span>Powered by</span>
                  <span style={{fontWeight: "900", marginLeft: "0.2rem"}}>Wargos Technologies</span>
                </ContentWargos>
              </>
              :
              <>
                <SeleccioneSala>Seleccione el casino de su preferencia:</SeleccioneSala>
                <SalaContent>
                  {siteInfo &&
                    siteInfo.salas.map((data, key) => (
                      <SalaItem key={key}>
                        <img
                          src={data.path}
                          rel="preload"
                          alt={data.name}
                          onClick={() => handleLogin(data, setSelectCasino, setSala)}
                        />
                      </SalaItem>
                    ))
                  }
                </SalaContent>
              </>
            }
          </ContenidoLogin>
        </CardLogin>
      }
    </LoginContainer>
  )
}

export default Login
