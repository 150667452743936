import styled, { keyframes } from 'styled-components'

const gradient =(props) => keyframes`
	0% {
    background: ${props.theme.login_bg_ini};
  }
  50% {
    background: ${props.theme.login_bg_mid};
  }
  100% {
    background: ${props.theme.login_bg_fin};
  }
`

export const LoginContainer =styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background: #fff;
	background-size: 400% 400%;
	animation: ${props => gradient(props)} 9s linear infinite alternate both;
`

export const CardLogin =styled.div`
  width: ${({ isSelect }) => (isSelect ? '400px' : '570px')};
  min-height: calc(100vh - 2rem);
  background: rgb(255 255 255 / 98%);
  border-radius: 4px;
  padding: 1rem;
  box-shadow: rgb(0 0 0 / 50%) 0px 2px 8px;
  transition: 0.3s all ease-in-out;
  @media screen and (max-width: 425px){
    transition: 0.3s all ease-in-out;
    width: 100%;
    padding: 1rem 0.5rem;
  }
`

export const ContenidoLogin =styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  gap:16px;
`

export const SeleccioneSala = styled.span`
 color: ${(props) => props.theme.text_default};
 width: 100%;
 display: flex;
 justify-content: center;
 font-size:14px;
 text-align:center;
`

export const SalaContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  align-items: center;
`

export const SalaItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 160px;
  height: 140px;
  justify-content: center;
  border-radius: 4px;
  background-color:#fff;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 8px;
  cursor: pointer;
  margin: 0.5rem;
  transition: 0.3s all ease-in-out;

  &:hover{
    transition: 0.3s all ease-in-out;
    transform: scale(1.1);
  }

  img{
    height: auto;
    width: auto;
    max-height: 150px;
    max-width: 150px;
    min-width: 140px;
    border-radius: 8px;
  }

  @media screen and (max-width: 425px){
    transition: 0.3s all ease-in-out;
    min-width: calc(50% - 1rem);
    height: 130px;
    img{
      max-width: 90%;
    }
  }
`

export const NombreSala = styled.span`
  text-transform: uppercase;
  color: ${(props) => props.theme.text_primary};
  font-weight: bold;
  font-size: 1.1rem;
  text-align:center;
`

export const SelectSala = styled.span`
  cursor: pointer;
  color: ${(props) => props.theme.text_default};
  font-size:0.9rem;
  text-align:center;
`

export const FormLogin = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  padding: 0 1rem 1rem;
`

export const FormLabel = styled.label`
  margin-bottom: 0.3rem;
  margin-top:1rem;
`

export const TextError = styled.span`
  text-align: center;
  color: ${(props) => props.theme.text_default};
  font-weight: bold;
  margin: 0px;
  text-transform: uppercase;
  font-size: 14px;
  width: 100%;
  display: flex;
  justify-content: center;
`

export const ContentCompany = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: ${(props) => props.theme.text_default_black};
  label{
    margin-bottom: 0.5rem;
  }
  a{
    margin-left: 0.5rem;
    text-decoration: none;
    color: inherit;
  }
`
export const ContentWargos = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: ${(props) => props.theme.text_default_black};
  opacity: 0.8;
  margin-bottom: 2rem;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
`

export const CustomCheckbox = styled.div`
  display:flex;
  flex-direction:row;
  align-items:center;
  margin:1rem 0px 0.5rem;
  label{
    font-size:14px;
  }
  label>span{
    color: ${(props) => props.theme.text_default};
    font-weight: bold;
    cursor: pointer;
  }
  label>a{
    color: ${(props) => props.theme.text_default};
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
  }
`