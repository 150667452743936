import React from 'react'
import styled from 'styled-components'

const CardContenedor = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-clip: border-box;
  border-radius: 8px;
  background: #fff;
  padding: ${p => p.tipo2 ? '1rem 1rem 1.5rem' : '1rem'};
  margin-bottom: ${p => p.tipo2 ? '1rem' : '1.5rem'};
  box-shadow: ${p => p.tipo2 ? 'inset 0px -8px 0px #AB1B1B' : 'none'};
  filter: drop-shadow(0 2px 4px rgb(0 0 0 / 40%));
  transition: 0.5s all ease-in-out;
  @media screen and (max-width: 425px) {
    transition: 0.5s all ease-in-out;
  }
`
const CardTitulo = styled.div`
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content: flex-start;
  width: 100%;
  color: #000;
  transition: 0.5s all ease-in-out;
  @media screen and (max-width: 425px) {
    transition: 0.5s all ease-in-out;
  }
  span{
    font-weight: bold;
  }
`

const CardDetalle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top:0.5rem;
  color: #000;
  text-transform: lowercase;
  transition: 0.5s all ease-in-out;
  @media screen and (max-width: 425px) {
    transition: 0.5s all ease-in-out;
  }

  strong {
    font-size: 1.2rem;
  }
`

const CustomCard = ({titulo,subtitulo,icono,detalle,cuerpo,tipo2}) => {
  return (
    <CardContenedor tipo2={tipo2}>
      <CardTitulo>
        {icono && <>{icono}</> }
        <span>{titulo}</span>
      </CardTitulo>

      {subtitulo && <span style={{fontSize:'12px',color:'#00000080',marginTop:'2px'}}>{subtitulo}</span>}

      {detalle &&
        <CardDetalle>
          <span>{detalle}</span>
        </CardDetalle>
      }

      {cuerpo &&
        <>{cuerpo}</>
      }

    </CardContenedor>
  )
}

export default CustomCard
