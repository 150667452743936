import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import useFetch from '../hooks/useFetch'
import {Apis} from "../util/Apis"
import {useNavigate} from "react-router"
import dateFormat from 'dateformat'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Cupon from '../components/templates/cuponTemplate'
import LoadingCard from '../components/generales/loading'
import NoElements from '../components/generales/noElements'
import { CustomButton2 } from '../components/generales/customButton'
import CustomCard from '../components/generales/card'
import DetalleCuponesTemplate from '../components/templates/detalleCuponesTemplate'

import sorteos from '../components/icons/sorteos.svg'
import { MdHistory, MdLocalPlay, MdStar } from 'react-icons/md'

const IconSorteos = styled.div`
  width: 24px;
  height: 25px;
  background-color: #000;
  -webkit-mask: url(${sorteos}) no-repeat center;
  mask: url(${sorteos}) no-repeat center;
`

export const TablaDetalle = styled.div`
  margin-top: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const TituloCategoria = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  color: #000;
  margin-bottom: 1rem;
  span {
    margin-left: 4px;
  }
`

const Resumen = () => {

  const salaInfo = JSON.parse(localStorage.getItem('salaInfo'))

  const {data: dataCoupon, loading: loadingCoupon} = useFetch(Apis.CAPP + '/point-coupons?salaId=' + salaInfo.salaId)

  const {data: dataHistory} = useFetch(Apis.CAPP + '/coupons/history')

  const [puntosCliente, setPuntosCliente] = useState(0)
  const [cuponesCliente, setCuponesCliente] = useState([])
  const [cuponesHistory, setCuponesHistory] = useState([])

  useEffect(() => {
    if (dataCoupon !== undefined && dataCoupon !== null) {
      setPuntosCliente(dataCoupon.points)
      setCuponesCliente(dataCoupon.coupons)
    }
  }, [dataCoupon])

  useEffect(() => {
    if (dataHistory !== undefined && dataHistory !== null) {
      setCuponesHistory(dataHistory)
    }
  }, [dataHistory])

  const navigate = useNavigate()

  function detalleCupones() {
    navigate("/cupones");
  }

  return (
    <>
      {loadingCoupon ?
        <LoadingCard/>
        :
        <Container fluid style={{marginTop: '-4rem', padding: '0 1rem'}}>
          <Row>
            <Col xs={12} sm={12} md={12}>
              
              <CustomCard titulo='Puntos'
                subtitulo='puntos acumulados'
                icono={<MdStar style={{fontSize:'1.5rem',marginRight:'4px'}}/>}
                detalle={<span> <strong>{Math.floor(puntosCliente)}</strong> puntos acumulados</span>}
              />
              {dataCoupon.activity.inPlay &&
                <CustomCard titulo='Sesión actual'
                  subtitulo='actualmente estas jugando en la posición'
                  icono={<IconSorteos style={{marginRight:'4px'}} />}
                  detalle={<span>Máquina: <strong>{dataCoupon.activity.machine}</strong></span>}
                />
              }

              <TituloCategoria>
                <MdLocalPlay style={{fontSize:'1.5rem'}}/> <span>Cupones </span>
              </TituloCategoria>
            </Col>

            {cuponesCliente.length > 0 ?
              <>
                {cuponesCliente.map((item, i) =>
                  <Col xs={12} sm={6} md={6} key={i}>
                    <Cupon nombre={item.couponName} cantidad={item.quantity} porcentaje={item.percentage}
                           cuponColor={item.hexColor}/>
                  </Col>
                )}
              </>
              :
              <Col xs={12} sm={12} md={12}>
                <NoElements titulo='No hay cupones disponibles en este momento'
                            subtitulo='Vuelva a intentarlo más tarde'/>
              </Col>
            }

            <Col xs={12} sm={12} md={12}>
              <CustomCard titulo='Detalle de cupones'
                subtitulo='Ultimos 5 cupones obtenidos'
                icono={<MdHistory style={{fontSize:'1.5rem',marginRight:'4px'}}/>}
                cuerpo={
                  <TablaDetalle>
                    {cuponesHistory.slice(0, 5).map((item, i) =>
                      <DetalleCuponesTemplate key={i} maquina={item.machine} fecha={dateFormat(item.createdAt, "yyyy-mm-dd hh:MM TT")} cupones={item.quantity} />
                    )}
                    <CustomButton2 size="sm" onClick={detalleCupones}>VER MÁS REGISTROS</CustomButton2>
                  </TablaDetalle>
                }
              />
            </Col>
          </Row>
        </Container>
      }
    </>
  )
}

export default Resumen
