import React, {useContext, useEffect, useState} from 'react'
import {Navigate} from 'react-router-dom'
import {Apis} from "../../util/Apis"
import axios from 'axios'
import jwt_decode from "jwt-decode"
import AuthContext from './store/AuthContext'
import {setCurrentUser} from "./actions/authentication.action"
import {useNavigate, useParams} from 'react-router'
import {
  CardLogin,
  ContenidoLogin,
  CustomCheckbox,
  FormLabel,
  FormLogin,
  LoginContainer,
  TextError
} from './loginElements'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import {CustomButton1, CustomButton2} from '../generales/customButton'
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs'

const Signup = () => {

  const {id} = useParams()
  const {stateUser, dispatch} = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const firstName = ''
  const lastName = ''
  const [document, setDocument] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [sala, setSala] = useState([]);
  const [siteInfo, setSiteInfo] = useState(null)

  const [passwordShown1, setPasswordShown1] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);

  const togglePassword1 = () => {
    setPasswordShown1(!passwordShown2);
  };

  const togglePassword2 = () => {
    setPasswordShown2(!passwordShown2);
  };

  const navigate = useNavigate();

  useEffect(() => {
    //console.log('doc : ' + id)
    setDocument(id)

    let salaLs = localStorage.getItem("salaInfo")
    if (salaLs !== undefined && salaLs !== null) {
      setSala(JSON.parse(salaLs))
    }
  }, [id])

  useEffect(() => {
    getSalas()
  }, [])

  const [state, setState] = useState({checkedA: false, checkedB: false})

  const handleChange = (event) => {
    setState({...state, [event.target.name]: event.target.checked});
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const isLoggedIn = stateUser.isAuthenticated
  const referer = '/home'

  if (isLoggedIn) {
    return <Navigate to={referer}/>;
  }

  const goLogin = () => {
    navigate("/login");
  }

  const onRegister = () => {
    setLoading(true)
    setError('')

    const data = {
      tenant: 'wgtcustomer',
      connection: sala.auth,
      //email = "hola@gmail.com"
      firstName: firstName,
      lastName: lastName,
      document: document,
      username: document,
      password: password,
      salaId: sala.salaId,
    }

    if (password.length < 6) {
      setError("La contraseña debe contener al menos 6 caracteres")
      setLoading(false)
      return;
    }

    if (password !== confirmPassword) {
      setError("Las contraseñas no coinciden")
      setLoading(false)
      return
    }

    // if (!state.checkedA) {
    //   setError("Debes aceptar las promociones especiales")
    //   setLoading(false)
    //   return
    // }
    //
    // if (!state.checkedB) {
    //   setError("Debes aceptar lapolitica de privacidad de datos")
    //   setLoading(false)
    //   return
    // }

    axios.post(Apis.OAUTH + '/oauth/signup', data)
      .then(function (res) {
        if (res.status === 200) {
          const token = res.data.access_token;
          const refreshToken = res.data.refreshToken;
          localStorage.setItem("auth", token);
          localStorage.setItem("wsrf", refreshToken);
          const decodedToken = jwt_decode(token);
          setError("")

          axios.post(Apis.CAPP + '/register', data).then(res => {
            dispatch(setCurrentUser(decodedToken));
            setLoading(false)
          }).catch(err => {
            setLoading(false)
            setLoading(false)
            if (error.response) {
              setError(error.response.data.message)
            } else {
              setError('Hubo un error desconocido')
            }
          })
        }
      })
      .catch(function (error) {
        setLoading(false)
        if (error.response) {
          setError(error.response.data.message)
        } else {
          setError('Hubo un error desconocido')
        }
      })
  }

  function getSalas() {
    axios.get(Apis.CAPP + '/site')
      .then(function (response) {
        setSiteInfo(response.data)
      })
      .catch(function (error) {
      })
  }

  return (
    <>
      {
        siteInfo &&
        <>
          <LoginContainer>
            <CardLogin isSelect={true}>
              <ContenidoLogin>
                <img src={`${siteInfo.logo}`} width='120' height='120' alt=''
                     style={{marginBottom: '0.5rem'}}/>

                <FormLogin>
                  <FormLabel>Documento</FormLabel>
                  <Form.Control disabled
                                type="text"
                                id="document"
                                label="Documento"
                                variant="outlined"
                                value={document}
                                onChange={(e) =>
                                  setDocument(e.target.value)
                                }
                  />
                  <Form.Group style={{position:'relative'}}>
                    <FormLabel>Contraseña</FormLabel>
                    <Form.Control required autoComplete="off"
                                  type={passwordShown1 ? "text" : "password"}
                                  id="password"
                                  onChange={(e) =>
                                    setPassword(e.target.value)
                                  }
                    />
                    <div style={{position:'absolute',bottom:'8px',right:'12px'}}>
                      {passwordShown1 ? 
                        <BsEyeSlashFill color='#323232' onClick={togglePassword1}/>
                        :
                        <BsEyeFill onClick={togglePassword1}/>
                      }
                    </div>
                  </Form.Group>

                  <Form.Group style={{position:'relative'}}>            
                    <FormLabel>Confirmar contraseña</FormLabel>
                    <Form.Control required autoComplete="off"
                                  type={passwordShown2 ? "text" : "password"}
                                  id="repeatpassword"
                                  onChange={(e) =>
                                    setConfirmPassword(e.target.value)
                                  }
                    />
                    <div style={{position:'absolute',bottom:'8px',right:'12px'}}>
                      {passwordShown2 ? 
                        <BsEyeSlashFill color='#323232' onClick={togglePassword2}/>
                        :
                        <BsEyeFill onClick={togglePassword2}/>
                      }
                    </div>
                  </Form.Group>  
                  {/*<CustomCheckbox>*/}
                  {/*  <Form.Check required checked={state.checkedA} onChange={handleChange} name="checkedA"*/}
                  {/*              style={{paddingLeft: "0", marginRight: '0.5rem'}}/>*/}
                  {/*  <label>Deseo recibir información de las <span*/}
                  {/*    onClick={handleShow}>Promociones especiales</span></label>*/}
                  {/*</CustomCheckbox>*/}

                  {/*<CustomCheckbox>*/}
                  {/*  <Form.Check required checked={state.checkedB} onChange={handleChange} name="checkedB"*/}
                  {/*              style={{paddingLeft: "0", marginRight: '0.5rem'}}/>*/}
                  {/*  <label>Ley de Política de <a href={window.location.origin + sala.privacidad}>Privacidad de*/}
                  {/*    datos</a></label>*/}
                  {/*</CustomCheckbox>*/}

                  <TextError>{error && error}</TextError>

                  <div style={{
                    display: "flex",
                    flexDirection: 'row',
                    justifyContent: "space-between",
                    width: "100%",
                    marginTop: '2rem'
                  }}>
                    <CustomButton1 onClick={goLogin} style={{width: "48%"}}>
                      REGRESAR </CustomButton1>
                    <CustomButton2 size="sm" onClick={onRegister} style={{width: "48%"}} disabled={loading}
                                   type="submit">
                      {loading ? "CARGANDO" : "REGISTRARSE"}</CustomButton2>
                  </div>
                </FormLogin>

              </ContenidoLogin>
            </CardLogin>
          </LoginContainer>
          <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>Promociones Especiales</Modal.Title>
            </Modal.Header>
            <Modal.Body>
          <span>
            Mediante su aceptación usted autoriza a <strong>{sala.company}</strong> (en adelante, “<strong>{sala.companyShort}</strong>”),
            a tratar sus datos personales proporcionados en el presente formulario para el envío de (i) saludos por cumpleaños y
             (ii) publicidad referente a promociones, ofertas, invitaciones a eventos y servicios de <strong>{sala.companyShort}</strong> a través de medios
             electrónicos y/o físicos y/o mediante llamadas telefónicas. Los datos personales obtenidos en el presente formulario
             quedarán almacenados en el banco de datos de Clientes de <strong>{sala.companyShort}</strong>.
          </span>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleClose}>ACEPTAR</Button>
            </Modal.Footer>
          </Modal>
        </>
      }
    </>
  )
}

export default Signup
