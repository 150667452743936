import React,{ useState, useEffect } from 'react'
import { BsGift } from 'react-icons/bs';
import { FaChartPie } from 'react-icons/fa';
import { MdCampaign, MdLocalPlay, MdOutlineEditCalendar, MdQrCodeScanner } from 'react-icons/md';
import { IconAnfora, LogoLink, SidebarLabel, SidebarLink, SidebarLogo, SidebarNav, SidebarWrap } from './sidebarElements'

const Sidebar = () => {

  const [sala, setSala] = useState([]);

  useEffect(() => {
    let checkSala = localStorage.getItem("salaInfo")
    if (checkSala !== undefined && checkSala !== null) {
      setSala(JSON.parse(checkSala))
    }
  }, [])

  return (
    <>
      <SidebarNav>
        <SidebarLogo>
          <LogoLink to='/cupones'>
            <img src={`${sala.path}`} alt='Casino' height='50px'/>
          </LogoLink>
        </SidebarLogo>
        <SidebarWrap>
          <SidebarLink to='/resumen' end={true}>
            <FaChartPie style={{fontSize:'1.8rem'}} />
            <SidebarLabel>Resumen</SidebarLabel>
          </SidebarLink>
          <SidebarLink to='/cupones'>
            <MdLocalPlay style={{fontSize:'1.8rem'}} />
            <SidebarLabel>Cupones</SidebarLabel>
          </SidebarLink>
          {/* <SidebarLink to='/main/puntos'>
            <MdStar style={{fontSize:'1.8rem'}} />
            <SidebarLabel>Puntos</SidebarLabel>
          </SidebarLink> */}
          <SidebarLink to='/anforas'>
            <IconAnfora />
            <SidebarLabel>Ánforas</SidebarLabel>
          </SidebarLink>
          <SidebarLink to='/sorteos'>
            <MdOutlineEditCalendar style={{fontSize:'1.8rem'}} />
            <SidebarLabel>Sorteos</SidebarLabel>
          </SidebarLink>
          <SidebarLink to='/recompensas'>
            <BsGift style={{fontSize:'1.8rem'}} />
            <SidebarLabel>Recompensas</SidebarLabel>
          </SidebarLink>
          <SidebarLink to='/promociones'>
            <MdCampaign style={{fontSize:'1.8rem'}} />
            <SidebarLabel>Promociones</SidebarLabel>
          </SidebarLink>
          {/* <SidebarLink to='/main/notificaciones'>
            <IconNotificaciones />
            <SidebarLabel>Notificaciones</SidebarLabel>
          </SidebarLink>
          <SidebarLink to='/main/contactanos'>
            <IconContactanos />
            <SidebarLabel>Contactanos</SidebarLabel>
          </SidebarLink> */}
          <SidebarLink to='/lector-qr'>
            <MdQrCodeScanner style={{fontSize:'1.8rem'}} />
            <SidebarLabel>QR</SidebarLabel>
          </SidebarLink>
        </SidebarWrap>
      </SidebarNav>
    </>
  );
};

export default Sidebar
