import React, {useEffect, useState} from 'react'
import QrReader from 'modern-react-qr-reader'
import axios from 'axios'
import styled,{ keyframes } from 'styled-components'
import {Apis} from "../util/Apis"
import {Col, Container, Modal, Row} from 'react-bootstrap'
import {useLocation, useNavigate} from "react-router"
import success_icon from '../components/icons/success_icon.svg'
import error_icon from '../components/icons/error_icon.svg'
import { ButtonError, ButtonSuccess } from '../components/generales/customButton'
import CustomCard from '../components/generales/card'
import { MdQrCodeScanner } from 'react-icons/md'

const customAnimation = keyframes`
 0% {
    -webkit-transform: rotate(-360deg) scale(0);
    transform: rotate(-360deg) scale(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
    transform: rotate(0) scale(1);
    opacity: 1;
  }
`

const ShowOnloadIcon = styled.img`
  width: 120px;
  margin-bottom: 1rem;
  -webkit-animation: ${customAnimation} 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: ${customAnimation} 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
`

const ModalTitle = styled.span`
  font-weight: 700;
  margin-bottom: 1rem;
  text-align:center;
`
const ModalMessage = styled.span`
  margin-bottom: 2rem;
  text-align:center;
`

const ContainerErrorMessage = styled.div`
  margin-bottom: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`

function useQuery() {
  const {search} = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}


const LectorQR = () => {

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [error2, setError2] = useState(null)
  const [show, setShow] = useState(false)
  let query = useQuery()
  const salaInfo = JSON.parse(localStorage.getItem('salaInfo'))
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [showModalError, setShowModalError] = useState(false);

  const delay = 2000

  useEffect(() => {
    const code = query.get('code')
    //console.log('query code: ' + code)
    if (code !== null && code !== '') {
      checkQr(code)
      setShow(false)
    } else {
      setShow(true)
    }
  }, [query])

  //funcion llamada por la libreria QR
  const handleScan = (data) => {
    //console.log('scanning...')
    //console.log(data)

    if (loading) {
      //console.log('busy')
      return
    }

    if (data !== undefined && data !== null) {
      const code = data.substring(26)
      checkQr(code)
    }
  }

  const checkQr = (code) => {
    if (code.length === 0) {
      return
    }

    setLoading(true)
    setError(null)

    //asumiento que la baseURL es : https://qr.wgt.pe/s/xxxxx
    const body = {code: code, salaId: salaInfo.salaId}

    axios.post(Apis.CAPP + '/qr-login', body).then(res => {
      console.log('logged successfully')
      setError(null)
      setLoading(false)
      successScan()
    }).catch((err) => {
      //console.log(err.response)
      setLoading(false)
      handleError(err, code)
      errorScan()
    })
  }

  const handleError = (err, data) => {
    //console.error(err.response)
    if (err.response) {
      //console.log(err.response.data); // => the response payload
      setError(err.response.data.message + ' | ' + data)
    } else {
      setError('Hubo un error')
    }
  }

  const handleCamError = (err2) => {
    setError2('Hubo un error para acceder a la camara')
    errorScan()
  }

  function successScan() {
    setShowModalError(false)
    setShowModalSuccess(true)

    const timer = setTimeout(() => {
      navigate('/home')
    }, 3000);
    return () => clearTimeout(timer);
  }
  
  function errorScan() {
    setShowModalError(true)
  }

  function goHome() {
    setShowModalSuccess(false)
    navigate('/home')
  }
  

  return (
    <Container fluid style={{marginTop: '-4rem', padding: '0 1rem'}}>
      <Row>
        <Col xs={12} sm={12} md={12}>
          {show && 
            <CustomCard tipo2
              titulo='Código QR'
              subtitulo='Escanea el codigo QR de la máquina y participa'
              icono={<MdQrCodeScanner style={{fontSize:'1.5rem',marginRight:'4px'}} />}
              cuerpo={
                <QrReader
                  delay={delay}
                  style={{width: '100%',marginTop:'1rem'}}
                  onError={handleCamError}
                  onScan={handleScan}
                  facingMode={"environment"}
                />
              }
            />
          }
        </Col>
      </Row>
      <Modal 
        show={showModalSuccess}
        onHide={() => setShowModalSuccess(false)}
        backdrop="static"
        keyboard={false}
        centered
        fullscreen
      >
        <Modal.Body style={{display:'flex',flexDirection:'column',justifyContent: 'center',alignItems: 'center'}}>
          <div style={{display:'flex',flexDirection:'column',alignItems: 'center'}}>
            <ShowOnloadIcon src={success_icon} alt='success'/>
            <ModalTitle>FELICIDADES</ModalTitle>
            <ModalMessage>Iniciaste sesión correctamente!</ModalMessage>
            <ButtonSuccess onClick={() => goHome()}>ACEPTAR</ButtonSuccess>
          </div>
        </Modal.Body>
      </Modal>

      <Modal 
        show={showModalError}
        onHide={() => setShowModalError(false)}
        backdrop="static"
        keyboard={false}
        centered
        fullscreen
      >
        <Modal.Body style={{display:'flex',flexDirection:'column',justifyContent: 'center',alignItems: 'center'}}>
          <div style={{display:'flex',flexDirection:'column',alignItems: 'center'}}>
            <ShowOnloadIcon src={error_icon} alt='success'/>
            <ModalTitle>
              {error && <span>{error}</span>}
              {error2 && <span>{error2}</span>}
            </ModalTitle>
            <ContainerErrorMessage>
              <span>Tuvimos problemas para iniciar sesión</span>
              <span>Vuelva a intentarlo!</span>
            </ContainerErrorMessage>
            
            <ButtonError onClick={() => setShowModalError(false)}>ACEPTAR</ButtonError>
          </div>
        </Modal.Body>
      </Modal>
    </Container>
  )
}

export default LectorQR
