import React, {useContext, useEffect, useState} from 'react'
import AuthContext from "../login/store/AuthContext"
import {callApiToSavePermissions} from "../login/actions/authentication.action"
import useFetch from "../../hooks/useFetch"
import {Apis} from "../../util/Apis"
import {askForPermissions, requestPermissionState} from "../../firebaseConfig"
import {
  CloseIcon,
  Icon,
  MenumobileContainer,
  MenumobileLabel,
  MenumobileLink,
  MenumobileWrapper,
  PerfilContainer,
  PerfilLink
} from './menumobileElements'
import {CustomButton1, CustomButton2} from "../generales/customButton"
import { MdNotificationsActive } from 'react-icons/md'


const PermissionDialog = ({isOpen, toggle, setIsOpenDi}) => {

  const {dispatch} = useContext(AuthContext)

  const {data: meData} = useFetch(Apis.CAPP + '/me')
  const [firstName, setFirstName] = useState(' -- ')

  useEffect(() => {
    if (meData !== undefined && meData !== null) {
      setFirstName(meData.firstName)
    }
  }, [meData])


  function acceptPermission() {
    console.log("Permiso aceptado por el cliente")
    setIsOpenDi(false);
    askForPermissions();
    //callApiToSavePermissions();
  }

  function rejectPermission() {
    console.log("Permiso denegado por el cliente")
    localStorage.setItem("permissionsFcm", "false")
    setIsOpenDi(false);
    console.log(requestPermissionState());
    callApiToSavePermissions();
  }

  return (
    <MenumobileContainer isOpen={isOpen} onClick={toggle}>
      <PerfilContainer>
        <PerfilLink to='/main/perfil' onClick={toggle}>
          <span style={{margin:"0 0.5rem",fontWeight:'bold'}} >Bienvenido(a), {firstName}</span>
        </PerfilLink>
        <Icon onClick={() => rejectPermission()}>
          <CloseIcon/>
        </Icon>
      </PerfilContainer>

      <MenumobileWrapper>
        <MenumobileLink to='/main' >
          <MdNotificationsActive style={{fontSize:'1.8rem'}} />
          <MenumobileLabel>Permisos para notificaciones</MenumobileLabel>
        </MenumobileLink>
        <CustomButton2 size='sm' onClick={() => rejectPermission()} style={{margin: '1rem'}}>Rechazar envío de
          notificaciones</CustomButton2>
        <CustomButton1 size='sm' onClick={() => acceptPermission()} style={{margin: '1rem'}}>Aceptar envío de
          notificaciones</CustomButton1>
        <h5> Si desea aceptar los permisos, acepte también en el navegador </h5>
      </MenumobileWrapper>
    </MenumobileContainer>
  )
}

export default PermissionDialog
