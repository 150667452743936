import React from 'react'
import { MdLocalPlay } from 'react-icons/md'
import styled from 'styled-components'
import sorteos from '../icons/sorteos.svg'

const IconSorteos = styled.div`
  width: 24px;
  height: 25px;
  background-color: #000;
  -webkit-mask: url(${sorteos}) no-repeat center;
  mask: url(${sorteos}) no-repeat center;
`


const DetalleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom:0.5rem;
  width:100%;
  transition: 0.5s all ease-in-out;
  @media screen and (max-width: 425px) {
    transition: 0.5s all ease-in-out;
  }
  &:last-child {
    margin-bottom:1rem;
  }
`

const DetalleCupones = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 0.5s all ease-in-out;
  @media screen and (max-width: 425px) {
    transition: 0.5s all ease-in-out;
  }
  strong{
    margin-right: 4px;
    font-size: 16px;
  }
`

const DetalleMaquina = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4px;
  align-items: flex-start;
  transition: 0.5s all ease-in-out;
  @media screen and (max-width: 425px) {
    transition: 0.5s all ease-in-out;
  }
  span{
    font-size: 12px;
    color: #00000080;
  }
`


const DetalleCuponesTemplate = ({maquina,fecha,cupones}) => {
  return (
    <DetalleContainer>
      <DetalleCupones>
        <IconSorteos style={{height:'30px',width:'28px'}} />
        <DetalleMaquina>
          <strong>{maquina}</strong>
          <span>{fecha}</span>
        </DetalleMaquina>
      </DetalleCupones>
      <DetalleCupones>
        <strong>{cupones}</strong> <MdLocalPlay style={{fontSize:'1.5rem'}} />
      </DetalleCupones>
    </DetalleContainer>
  )
}

export default DetalleCuponesTemplate