import jwt_decode from 'jwt-decode'
import myAuthApi from '../myAuthApi'
import {Apis} from '../../../util/Apis'
import axios from "axios";

export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const loginUser = (username, password, connection, dispatch, setError, setLoading) => { //login

  const TOKEN_AUTH_USERNAME = process.env.REACT_APP_AUTH_CLIENT_ID
  const TOKEN_AUTH_PASSWORD = '123456'
  const TENANT = 'wgtcustomer'

  const body = `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}&grant_type=password&tenant=${TENANT}&connection=${connection}&client_id=${TOKEN_AUTH_USERNAME}`;

  let config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic ' + btoa(TOKEN_AUTH_USERNAME + ':' + TOKEN_AUTH_PASSWORD)
    }
  };

  setLoading(true);
  myAuthApi.post(Apis.OAUTH + '/oauth/token', body, config).then(res => {
    if (res.status === 200) {
      setLoading(false)
      const token = res.data.access_token;
      const refreshToken = res.data.refreshToken;
      localStorage.setItem("auth", token);
      localStorage.setItem("wsrf", refreshToken);
      const decodedToken = jwt_decode(token);
      setError("")
      dispatch(setCurrentUser(decodedToken));
    }
  }).catch(err => {
    setLoading(false)
    setError("Credenciales invalidas")
    //console.log('hubo un error en auth : ' + err)
    //setError(err.message)
  });

};

export const logoutUser = (dispatch) => {//logout
  localStorage.removeItem("auth");
  localStorage.removeItem("wsrf");
  //localStorage.clear();
  dispatch(setCurrentUser({}));
  callApiToDeleteToken();
};

export const setCurrentUser = decodedToken => {//si se loguea , setear datos del usuario
  return {
    type: SET_CURRENT_USER,
    payload: decodedToken
  };
};

export const callApiToSaveToken = () => {
  let data = {}
  data.token = localStorage.getItem("tokenFcm")
  if (data.token == null || data.token.length <= 0) {
    // getTokenCustomer()
    console.log("not token found")
    return;
  }
  axios.post(Apis.CAPP + '/token', data).then(res => {
  }).catch(error => {
    console.log("Error en guardar token de notificaciones")
  })
}

export function callApiToSavePermissions() {
  const data = {
    notificationsAccepted : localStorage.getItem("permissionsFcm") === 'true',
    salaId : JSON.parse(localStorage.getItem("salaInfo")).salaId
  }

  axios.post(Apis.CAPP + '/token/save-permissions', data).then(res => {
  }).catch(error => {
    console.log("Error en guardar permisos de notificaciones")
  })
}

export function callApiToDeleteToken() {
  let data = {}
  data.token = localStorage.getItem("tokenFcm")
  if (data.token != null && data.token.length > 0) {
    axios.post(Apis.CAPP + '/token/delete', data)
      .then(res => {
      })
      .catch(error => {
        console.log("Error en guardar token de notificaciones")
      })
  }
}
