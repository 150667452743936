import React from 'react'
import styled from 'styled-components'
import {useNavigate} from "react-router"

import {CustomButton2} from '../generales/customButton'

const AnforaCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  border-radius: 10px;
  background: white;
  box-shadow: inset 10px 0px 0px ${p => p.inputColor ? p.inputColor : '#ff2263'};
  filter: drop-shadow(2px 4px 6px rgb(0 0 0 / 40%));
  transition: 0.5s all ease-in-out;
  color: ${(props) => props.theme.text_primary};
  margin-bottom: 1rem;
`

const CuerpoAnfora = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width:100%;
  padding: 1rem 1rem 1rem 1.5rem;
`

const TituloAnfora = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  text-transform: uppercase;
  transition: 0.5s all ease-in-out;

  @media screen and (max-width: 350px) {
    transition: 0.5s all ease-in-out;
  }
`

const SubTituloAnfora = styled.span`
  color: rgb(0 0 0 / 55%);
  font-size: 12px;
  transition: 0.5s all ease-in-out;

  @media screen and (max-width: 350px) {
    transition: 0.5s all ease-in-out;
    font-size:10px;
  }
`

const CuponesDisponibles = styled.div`
  width: 100%;
  text-align: start;
  margin-top:8px;
  transition: 0.5s all ease-in-out;

  @media screen and (max-width: 350px) {
    span {
      transition: 0.5s all ease-in-out;
      font-size: 0.8rem;
    }
  }
`

const Anfora = ({nombre, disponibles, depositados, id, anforaColor}) => {

  const navigate = useNavigate()

  function goToDepositarCupones() {
    navigate("/depositar-cupones/" + id);
  }

  return (
    <AnforaCard inputColor={anforaColor}>
      <CuerpoAnfora>
        <div style={{width:'100%',display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
          <TituloAnfora>{nombre} </TituloAnfora>
          {depositados > 0 ?
            <SubTituloAnfora>Has depositado <strong>{depositados}</strong> cupon(es)</SubTituloAnfora>
            :
            <SubTituloAnfora>Aún no has depositado cupones</SubTituloAnfora>
          }
          <CuponesDisponibles>
            <span><strong>{disponibles}</strong> cupon(es) disponible(s)</span>
          </CuponesDisponibles>
        </div>
        <div style={{marginTop: '1rem'}}>
          <CustomButton2 size="sm" onClick={goToDepositarCupones} disabled={disponibles <= 0}>DEPOSITAR CUPONES</CustomButton2>
        </div>  
      </CuerpoAnfora>
    </AnforaCard>
  )
}

export default Anfora
