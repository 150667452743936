import React, { useState, useEffect } from 'react'
import useFetch from "../../hooks/useFetch"
import {Apis} from "../../util/Apis"

import { IconAnfora, CloseIcon, Icon, MenumobileContainer, MenumobileLabel, MenumobileLink, MenumobileWrapper, PerfilContainer, PerfilLink} from './menumobileElements'
import { FaChartPie, FaUser } from 'react-icons/fa'
import { MdCampaign, MdLocalPlay, MdOutlineEditCalendar, MdQrCodeScanner } from 'react-icons/md'
import { BsGift } from 'react-icons/bs'

const MenuMobile = ({isOpen,toggle}) => {

  const {data: meData } = useFetch(Apis.CAPP + '/me')
  const [firstName, setFirstName] = useState(' -- ')

  useEffect(() => {
    if (meData !== undefined && meData !== null) {
      setFirstName(meData.firstName)
    }
  }, [meData])

  return (
    <MenumobileContainer isOpen={isOpen} onClick={toggle} >
      
      <PerfilContainer>
        <PerfilLink to='/perfil' onClick={toggle}>
          <span style={{margin:"0 0.5rem",fontWeight:'bold'}} >Bienvenido(a), {firstName}</span>
        </PerfilLink>
        <Icon onClick={toggle} >
          <CloseIcon />
        </Icon>
      </PerfilContainer>

      <MenumobileWrapper>
          <MenumobileLink to='/resumen' onClick={toggle} end={true}>
            <FaChartPie style={{fontSize:'1.8rem'}} />
            <MenumobileLabel>Resumen</MenumobileLabel>
          </MenumobileLink>
          <MenumobileLink to='/cupones' onClick={toggle}>
            <MdLocalPlay style={{fontSize:'1.8rem'}} />
            <MenumobileLabel>Cupones</MenumobileLabel>
          </MenumobileLink>
          {/* <MenumobileLink to='/main/puntos' onClick={toggle}>
          <img src={puntos} alt='puntos' width='30px'/>
            <MenumobileLabel>Puntos</MenumobileLabel>
          </MenumobileLink> */}
          <MenumobileLink to='/anforas' onClick={toggle}>
            <IconAnfora />
            <MenumobileLabel>Ánforas</MenumobileLabel>
          </MenumobileLink>
          <MenumobileLink to='/sorteos' onClick={toggle}>
            <MdOutlineEditCalendar style={{fontSize:'1.8rem'}} />
            <MenumobileLabel>Sorteos</MenumobileLabel>
          </MenumobileLink>
          <MenumobileLink to='/recompensas' onClick={toggle}>
            <BsGift style={{fontSize:'1.8rem'}} />
            <MenumobileLabel>Recompensas</MenumobileLabel>
          </MenumobileLink>
          <MenumobileLink to='/promociones' onClick={toggle}>
            <MdCampaign style={{fontSize:'1.8rem'}} />
            <MenumobileLabel>Promociones</MenumobileLabel>
          </MenumobileLink>
          {/* <MenumobileLink to='/main/notificaciones' onClick={toggle}>
          <img src={notificaciones} alt='notificaciones' width='30px'/>
            <MenumobileLabel>Notificaciones</MenumobileLabel>
          </MenumobileLink>
          <MenumobileLink to='/main/contactanos' onClick={toggle}>
          <img src={contactanos} alt='contactanos' width='30px'/>
            <MenumobileLabel>Contactanos</MenumobileLabel>
          </MenumobileLink> */}
          <MenumobileLink to='/lector-qr' onClick={toggle}>
            <MdQrCodeScanner style={{fontSize:'1.8rem'}} />
            <MenumobileLabel>QR</MenumobileLabel>
          </MenumobileLink>
          <MenumobileLink to='/perfil' onClick={toggle}>
            <FaUser style={{fontSize:'1.8rem'}} />
            <MenumobileLabel>Perfil</MenumobileLabel>
          </MenumobileLink>

      </MenumobileWrapper>
    </MenumobileContainer>
  )
}

export default MenuMobile
