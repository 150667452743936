import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

*::-webkit-scrollbar-thumb {
  background: #888888;
  border-radius: 3px;
}

*::-webkit-scrollbar-track {
  background: #e1e5eb;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #531C99 !important;
  width: 16px !important;
  transition: 0.2s all ease-in-out;
  border-radius: 4px !important;
}

.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 20px !important;
}
`

export const dreamsColor = {
  login_bg_ini:'#132392',
  login_bg_mid:'#d835fa',
  login_bg_fin:'#1a6cc7',

  text_primary:'#0A1033',
  text_secondary:'#fff',
  text_default:'#005ac1',
  text_default_black:'#2D3142',

  btn_bg_primary:'#132392',
  btn_bg_secondary:'#fff',
  
  btn_text_primary:'#fff',
  btn_text_secondary:'#0a1033',

  btn_border_primary:'#132392',
  btn_border_secondary:'#0a1033',

  btn_hover_primary:'#2c46f9',
  btn_hover_secondary:'#b7b7b7',


  bg_primary:'#0A1033',
  bg_secondary:'linear-gradient(179deg,#D835FA 0%,#531C99 65px)',

  bg_primary_hover:'#0A1033',
  bg_secondary_hover:'#eaeaea',

  icon_primary:'#0A1033',
  icon_secondary:'#fff',

  loading:'linear-gradient(90deg,#D835FA,#531C99,#D835FA,#531C99)',
  loading_bg:'#d9dbdd',

  select_item_primary:'#0a1033',
  select_item_secondary:'#D835FA'
}

export const wargosColor = {
  login_bg_ini:'#f5bb61',
  login_bg_mid:'#ff9c01a6',
  login_bg_fin:'#e62500ab',
  
  text_primary:'#202020',
  text_secondary:'#ffbc40',
  text_default:'#A57D02',
  text_default_black:'#000',

  btn_bg_primary:'#ffa500bf',
  btn_bg_secondary:'#fff',
  
  btn_text_primary:'#202020',
  btn_text_secondary:'#202020',

  btn_border_primary:'#ffa500e6',
  btn_border_secondary:'#202020',

  btn_hover_primary:'#e67b00',
  btn_hover_secondary:'#ffa50059',


  bg_primary:'#202020',
  bg_secondary:'linear-gradient(179deg,#202020 0%,#4b4b4b 65px)',

  bg_primary_hover:'#202020',
  bg_secondary_hover:'#eaeaea',

  icon_primary:'#202020',
  icon_secondary:'#ffbc40',

  loading:'linear-gradient(90deg,#ffa500,#bd7016,#ffa500,#bd7016)',
  loading_bg:'#d9dbdd',

  select_item_primary:'#202020',
  select_item_secondary:'#ffbc40'
};

export const megaGamingColor = {
  login_bg_ini:'#79cdff',
  login_bg_mid:'#00189d',
  login_bg_fin:'#79cdff',

  text_primary:'#202020',
  text_secondary:'#ffbc40',
  text_default:'#A57D02',
  text_default_black:'#000',

  btn_bg_primary:'#ffa500bf',
  btn_bg_secondary:'#fff',
  
  btn_text_primary:'#202020',
  btn_text_secondary:'#202020',

  btn_border_primary:'#ffa500e6',
  btn_border_secondary:'#202020',

  btn_hover_primary:'#e67b00',
  btn_hover_secondary:'#ffa50059',


  bg_primary:'#202020',
  bg_secondary:'linear-gradient(179deg,#202020 0%,#4b4b4b 65px)',

  bg_primary_hover:'#202020',
  bg_secondary_hover:'#eaeaea',

  icon_primary:'#202020',
  icon_secondary:'#ffbc40',

  loading:'linear-gradient(90deg,#ffa500,#bd7016,#ffa500,#bd7016)',
  loading_bg:'#d9dbdd',

  select_item_primary:'#202020',
  select_item_secondary:'#ffbc40'
};

export const latinColor = {
  login_bg_ini:'#640809',
  login_bg_mid:'#fe3c3c',
  login_bg_fin:'#ab1b1b',
  
  text_primary:'#323232',
  text_secondary:'#fff',
  text_default:'#A57D02',
  text_default_black:'#000',

  btn_bg_primary:'#AB1B1B',
  btn_bg_secondary:'#fff',
  
  btn_text_primary:'#fff',
  btn_text_secondary:'#AB1B1B',

  btn_border_primary:'#AB1B1B',
  btn_border_secondary:'#AB1B1B',

  btn_hover_primary:'#610305',
  btn_hover_secondary:'#EAEAEA',


  bg_primary:'#AB1B1B',
  bg_secondary:'linear-gradient(179deg,#AB1B1B 0%,#E57505 65px)',

  bg_primary_hover:'#610305',
  bg_secondary_hover:'#eaeaea',

  icon_primary:'#610305',
  icon_secondary:'#fff',

  loading:'linear-gradient(90deg,#ff0000,#940709,#ff0000,#940709)',
  loading_bg:'#d9dbdd',

  select_item_primary:'#AB1B1B',
  select_item_secondary:'#AB1B1B'
};

export const stopColor = {
  login_bg_ini:'#eca807',
  login_bg_mid:'#f5a31b',
  login_bg_fin:'#ffc63f',
  
  text_primary:'#323232',
  text_secondary:'#fff',
  text_default:'#A57D02',
  text_default_black:'#000',

  btn_bg_primary:'#AB1B1B',
  btn_bg_secondary:'#fff',
  
  btn_text_primary:'#fff',
  btn_text_secondary:'#AB1B1B',

  btn_border_primary:'#AB1B1B',
  btn_border_secondary:'#AB1B1B',

  btn_hover_primary:'#610305',
  btn_hover_secondary:'#EAEAEA',


  bg_primary:'#AB1B1B',
  bg_secondary:'linear-gradient(179deg,#AB1B1B 0%,#E57505 65px)',

  bg_primary_hover:'#610305',
  bg_secondary_hover:'#eaeaea',

  icon_primary:'#610305',
  icon_secondary:'#fff',

  loading:'linear-gradient(90deg,#ff0000,#940709,#ff0000,#940709)',
  loading_bg:'#d9dbdd',

  select_item_primary:'#AB1B1B',
  select_item_secondary:'#AB1B1B'
};