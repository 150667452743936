import styled from 'styled-components'
import {NavLink} from 'react-router-dom'
import {FaTimes} from 'react-icons/fa'

import anfora from '../icons/anfora.svg'

export const MenumobileContainer = styled.div`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.3s all ease-in-out;
  overflow-y: auto;
  opacity: ${({isOpen}) => (isOpen ? '100%' : '0')};
  top: ${({isOpen}) => (isOpen ? '0' : '-100%')};
`
export const PerfilContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: ${(props) => props.theme.bg_primary};
  padding: 0px 1rem;
  width: 100%;
  min-height: 60px;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
`

export const PerfilLink = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1rem;
  transition: 0.2s all ease-in-out;
  color: ${(props) => props.theme.text_secondary};
  cursor: pointer;
  text-decoration: none;
  list-style: none;
`

export const CloseIcon = styled(FaTimes)`
  color: ${(props) => props.theme.icon_secondary};
`

export const Icon = styled.div`
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
  transform: translate(-20%, 0%);
`

export const MenumobileWrapper = styled.div`
  color: ${(props) => props.theme.text_primary};
  background: #fff;
  width: 100%;
  padding: 1rem;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  align-items: center;
`

export const MenumobileLink = styled(NavLink)`
  display: flex;
  color: ${(props) => props.theme.text_primary};
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 100%;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 1rem;
  transition: 0.3s all ease-in-out;

  &.active {
    box-shadow: inset 0 -5px ${(props) => props.theme.select_item_secondary};
    background-color: ${(props) => props.theme.bg_secondary_hover};
    color: ${(props) => props.theme.text_primary};
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    border-radius: 10px 10px 0 0;

    div {
      background-color: ${(props) => props.theme.text_primary};
    }
  }

  &:hover {
    box-shadow: inset 0 -5px ${(props) => props.theme.select_item_secondary};
    background-color: ${(props) => props.theme.bg_secondary_hover};
    color: ${(props) => props.theme.text_primary};
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    border-radius: 10px 10px 0 0;
  }
`
export const MenumobileLabel = styled.span`
  margin-left: 12px;
`
export const IconAnfora = styled.div`
  width: 30px;
  height: 32px;
  background-color: ${(props) => props.theme.text_primary};
  -webkit-mask: url(${anfora}) no-repeat center;
  mask: url(${anfora}) no-repeat center;
`
