import React, {useContext, useEffect} from 'react'
import {useParams} from "react-router";
import {SET_CURRENT_USER} from "./actions/authentication.action";
import jwt_decode from "jwt-decode";
import AuthContext from "./store/AuthContext";
import {Navigate} from "react-router-dom";
import axios from "axios";
import {Apis} from "../../util/Apis";

const MagicLink = () => {

  const {code} = useParams()
  const {stateUser, dispatch} = useContext(AuthContext);

  useEffect(() => {
    sendMagicCode()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function sendMagicCode() {

    const TOKEN_AUTH_USERNAME = process.env.REACT_APP_AUTH_CLIENT_ID
    const TOKEN_AUTH_PASSWORD = '123456'
    const TENANT = 'wgtcustomer'

    const body = `code=${code}&tenant=${TENANT}&clientId=${TOKEN_AUTH_USERNAME}`;

    let config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic ' + btoa(TOKEN_AUTH_USERNAME + ':' + TOKEN_AUTH_PASSWORD)
      }
    };

    // const data = {code: code,clientId: process.env.REACT_APP_AUTH_CLIENT_ID}
    axios.post(Apis.OAUTH + '/oauth/verify-magic-code',body,config).then(res => {
      if (res.status === 200) {
        const token = res.data.jwt;
        const refreshToken = res.data.refreshToken;
        let params = {connection: res.data.connection};
        axios.get(Apis.CAPP + '/site/connection', {params: params})
            .then(function (response) {
              localStorage.setItem("salaInfo", JSON.stringify(response.data))
              localStorage.setItem("auth", token);
              localStorage.setItem("wsrf", refreshToken);
              const decodedToken = jwt_decode(token);
              dispatch(setCurrentUser(decodedToken));
        })
        .catch(function (error) {
        })
      }
    }).catch(() => {
      console.log("Error en verificar magic link")
    })
  }

  const referer = '/home'
  const isLoggedIn = stateUser.isAuthenticated

  if (isLoggedIn) {
    console.log('is logged ... redirecting')
    return <Navigate to={referer}/>
  }

  return (
      <div>
        Cargando...
      </div>
  )
}

export default MagicLink

export const setCurrentUser = decodedToken => {//si se loguea , setear datos del usuario
  return {
    type: SET_CURRENT_USER,
    payload: decodedToken
  };
};
