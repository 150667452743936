import React from 'react'
import styled from 'styled-components'
import Table from 'react-bootstrap/Table'
import dateFormat from 'dateformat'

import {CustomButton2} from '../generales/customButton'
import {useNavigate} from "react-router";
import CustomCard from '../generales/card'
import { MdLocalPlay } from 'react-icons/md'
import { BsCash, BsGift } from "react-icons/bs";

const ContenedorDesktop = styled.div`
  box-shadow: 0 0.125rem 0.625rem rgb(90 97 105 / 40%);
  background-clip: border-box;
  border-radius: 4px;
  margin-bottom: 1rem;
  transition: 0.5s all ease-in-out;

  table {
    color: ${(props) => props.theme.text_primary} !important;
  }

  @media screen and (max-width: 768px) {
    transition: 0.5s all ease-in-out;
    display: none;
  }
`

const ContenedorMobile = styled.div`
  transition: 0.5s all ease-in-out;
  display: none;
  @media screen and (max-width: 768px) {
    transition: 0.5s all ease-in-out;
    display: block;
  }
`
const ContenidoDetalle = styled.div`
  width: 100%;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
const ContenedorBoton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1rem;
  text-align: center;
`

const Sorteo = ({datasorteo}) => {

  const salaInfo = JSON.parse(localStorage.getItem('salaInfo'))
  const navigate = useNavigate()

  function handleClick() {
    navigate("/anforas");
  }

  function mostrarTitulo(idSala) {
    switch(idSala) {
      case 63:
        return 'BIG WIN'
      case 65:
        return 'HOT WIN'
      case 80:
        return 'HAPPY HOURS'
      case 81:
        return 'HAPPY HOURS'
      default:
        return 'SORTEO HOT SEAT'
    }
  }

  function tipoSorteo(kind) {
    switch(kind) {
      case 'MANUAL':
        return 'sorteo con cupones físicos'
      case 'VIRTUAL':
        return 'sorteo con cupones virtuales'
      case 'HOT_SEAT':
        return 'sorteo por máquinas'
      default:
        return 'sorteo con cupones físicos'
    }
  }

  return (
    <>
      <ContenedorDesktop>
        <Table striped bordered hover responsive size="sm">
          <thead>
          <tr>
            <th className='text-center'>Hora</th>
            <th>Promoción</th>
            <th className='text-center'>Premio</th>
            <th className='text-center'>Estado</th>
            <th className='text-center'>Acciones</th>
          </tr>
          </thead>
          <tbody>
          {
            datasorteo.map((item, i) => (
              <tr key={i}>
                <td className='text-center'>{dateFormat(item.fechaHora, "yyyy-mm-dd hh:MM TT")}</td>
                <td>
                  { item.kind === 'HOT_SEAT' ?
                    <>{mostrarTitulo(salaInfo.salaId)}</>
                    :
                    <>{item.couponTypeName}</>
                  }
                </td>
                <td>
                  <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span >{item.amount + ' ' + item.premioTipo} </span>
                    {item.premioTipo === 'soles' ?
                      <BsCash style={{fontSize:'18px',marginLeft:'4px'}} />
                      :
                      <BsGift style={{fontSize:'18px',marginLeft:'4px'}}/>
                    }
                  </div>
                </td>
                <td className='text-center'>{item.locked ? ' REALIZADO' : 'PENDIENTE'}</td>
                <td className='text-center'>
                  <CustomButton2 onClick={handleClick} style={{fontSize: '0.7rem'}}
                    disabled={item.locked || item.kind === 'HOT_SEAT' || item.kind === 'MANUAL'}>PARTICIPAR</CustomButton2>
                </td>
              </tr>
            ))
          }
          </tbody>
        </Table>
      </ContenedorDesktop>
      <ContenedorMobile>
        {
          datasorteo.map((item, i) => (
            <CustomCard key={i} tipo2
              titulo={ item.kind === 'HOT_SEAT' ?
                <>{mostrarTitulo(salaInfo.salaId)}</>
                :
                <>{item.couponTypeName}</>
              }
              subtitulo={tipoSorteo(item.kind)}
              icono={<MdLocalPlay style={{fontSize:'1.5rem',marginRight:'4px'}}/>}
              cuerpo={
                <>
                  <ContenidoDetalle>
                    <span>Fecha: <strong>{dateFormat(item.fechaHora, "yyyy-mm-dd hh:MM TT")}</strong></span>
                    <span style={{display:'flex',flexDirection:'row',alignItems:'center'}}>Premio: <strong>{item.amount + ' ' + item.premioTipo}</strong> 
                      {item.premioTipo === 'soles' ?
                        <BsCash style={{fontSize:'18px',marginLeft:'4px'}} />
                        :
                        <BsGift style={{fontSize:'18px',marginLeft:'4px'}}/>
                      }
                    </span>
                    <span>Estado: <strong> {item.locked ? ' REALIZADO' : 'PENDIENTE'}</strong></span>
                  </ContenidoDetalle>
                  <ContenedorBoton>
                    <CustomButton2 size="sm" onClick={handleClick}
                      disabled={item.locked || item.kind === 'HOT_SEAT' || item.kind === 'MANUAL'}>PARTICIPAR</CustomButton2>
                  </ContenedorBoton>
                </>
              }
            />
          ))
        }
      </ContenedorMobile>
    </>
  )
}

export default Sorteo
