import React,{useState} from 'react'
import styled, {keyframes} from 'styled-components'
import ProgressBar from 'react-bootstrap/ProgressBar'

import {CustomButton1, CustomButton2} from '../generales/customButton'
import {useNavigate} from "react-router";


const gradient = keyframes`
  0% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
`
const CuponCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  border-radius: 8px;
  padding: 1rem 1rem 1rem 1.5rem;
  box-shadow: inset 8px 0px 0px ${p => p.inputColor ? p.inputColor : '#ff2263'};
  filter: drop-shadow(2px 4px 6px rgb(0 0 0 / 40%));
  transition: 0.5s all ease-in-out;
  color: ${(props) => props.theme.text_primary};
  margin-bottom: 1rem;
  background: #fff;
`

const ContenedorTitulo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-transform: uppercase;
  text-align: left;

`
const NombreCupon = styled.span`
  font-weight: bold;
  transition: 0.5s all ease-in-out;

  @media screen and (max-width: 350px) {
    transition: 0.5s all ease-in-out;
    font-size: 0.9rem;
  }
`

const TipoCupon = styled.span`
  color: rgb(0 0 0 / 55%);
  font-size:12px;
  transition: 0.5s all ease-in-out;

  @media screen and (max-width: 350px) {
    transition: 0.5s all ease-in-out;
    font-size:10px;
  }
`
const CantidadCupon = styled.div`
  width: 100%;
  text-align: start;
  margin-top:8px;
  transition: 0.5s all ease-in-out;

  @media screen and (max-width: 350px) {
    span {
      transition: 0.5s all ease-in-out;
      font-size: 0.8rem;
    }
  }
`

const ContenedorPromocion = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.5s all ease-in-out;
`
const PromocionTitulo = styled.span`
  font-size: 10px;
  transition: 0.5s all ease-in-out;

  @media screen and (max-width: 350px) {
    transition: 0.5s all ease-in-out;
    font-size: 9px;
  }
`

const ContenedorMultiplicador = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 16px;
  padding: 4px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.5s all ease-in-out;
`

const MultiplicadorTitulo = styled.span`
  font-size: 12px;
  color: white;
  text-align:center;
  white-space: nowrap;
  transition: 0.5s all ease-in-out;

  @media screen and (max-width: 350px) {
    transition: 0.5s all ease-in-out;
    font-size: 10px;
  }
`
const CustomButtonCupon1 = styled(CustomButton1)`
  width: auto;
  margin: 0 8px 0 0;
  transition: 0.5s all ease-in-out;
  @media screen and (max-width: 1120px) {
    transition: 0.5s all ease-in-out;
    width: 100%;
    margin: 0 0 8px 0;
  }
`

const CustomButtonCupon2 = styled(CustomButton2)`
  width: auto;
  transition: 0.5s all ease-in-out;
  @media screen and (max-width: 1120px) {
    transition: 0.5s all ease-in-out;
    width: 100%;
  }
`

export const CustomProgressBar = styled(ProgressBar)`
  background: ${(props) => props.theme.loading_bg};
  box-shadow: 0px 2px 3px 0px rgb(90 97 105 / 40%);

  > div {
    background: ${(props) => props.theme.loading};
    box-shadow: 0px 2px 3px 0px rgb(90 97 105 / 40%);
    background-size: 300% 100%;
    animation: ${gradient} 1s linear infinite;
  }
`

const Cupon = ({nombre, cantidad, porcentaje, detalle, cuponColor, id}) => {

  const navigate = useNavigate()

  function depositarCupones() {
    navigate("/anforas");
  }

  function detalleCupones() {
    navigate("/detalle-cupones/" + id);
  }

  const [cuponFisico, setCuponesFisico] = useState(false)

  return (
    <CuponCard inputColor={cuponColor}>
      <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
        <ContenedorTitulo>
          <NombreCupon>{nombre}</NombreCupon>
          <TipoCupon>CUPÓN FISICO</TipoCupon>
        </ContenedorTitulo>
        <ContenedorPromocion>
          <PromocionTitulo>PROMOCIÓN</PromocionTitulo>
          <ContenedorMultiplicador>
            <MultiplicadorTitulo>CUPONES X1</MultiplicadorTitulo>
          </ContenedorMultiplicador>
        </ContenedorPromocion>
      </div>
      

      <CantidadCupon>
        <span><strong>{cantidad}</strong> cupon(es) acumulados(s)</span>
      </CantidadCupon>

      <div style={{width: '100%', margin: '0.5rem 0'}}>
        <CustomProgressBar animated now={porcentaje} label={porcentaje + '%'}/>
      </div>

      {detalle &&
        <div style={{marginTop: '0.5rem'}}>
          <CustomButtonCupon1 size="sm" onClick={detalleCupones}>DETALLE DE CUPONES</CustomButtonCupon1>
          {cuponFisico && <CustomButtonCupon2 size="sm" onClick={depositarCupones}>DEPOSITAR CUPONES</CustomButtonCupon2>}
        </div>
      }
    </CuponCard>
  )
}

export default Cupon
