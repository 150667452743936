import React, {useEffect, useState} from 'react'
import useFetch from '../hooks/useFetch'
import {Apis} from "../util/Apis"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Cupon from '../components/templates/cuponTemplate'
import CustomCard from '../components/generales/card'
import LoadingCard from '../components/generales/loading'
import NoElements from '../components/generales/noElements'

import { MdLocalPlay } from 'react-icons/md'

const Cupones = () => {

  const salaInfo = JSON.parse(localStorage.getItem('salaInfo'))
  const {data, loading} = useFetch(Apis.CAPP + '/point-coupons?salaId=' + salaInfo.salaId)
  const [cuponesCliente, setCuponesCliente] = useState([])

  useEffect(() => {
    if (data !== undefined && data !== null) {
      setCuponesCliente(data.coupons)
    }
  }, [data])

  return (
    <>
      {loading ?
        <LoadingCard/>
        :
        <Container fluid style={{marginTop: '-4rem', padding: '0 1rem'}}>

          <Row>
            <Col xs={12} sm={12} md={12}>
              <CustomCard titulo='Cupones'
                subtitulo='lista de cupones activos actualmente'
                icono={<MdLocalPlay style={{fontSize:'1.5rem',marginRight:'4px'}}/>}
                detalle={<span> <strong>{cuponesCliente.length}</strong> cupon(es) activo(s)</span>}
              />
            </Col>
            {cuponesCliente.length > 0 ?
              <>
                {cuponesCliente.map((item, i) =>
                  <Col xs={12} sm={6} md={6} key={i}>
                    <Cupon nombre={item.couponName} cantidad={item.quantity} porcentaje={item.percentage}
                           cuponColor={item.hexColor} id={item.id} detalle/>
                  </Col>
                )}
              </>
              :
              <Col xs={12} sm={12} md={12}>
                <NoElements titulo='No hay cupones disponibles en este momento'
                            subtitulo='Vuelva a intentarlo más tarde'/>
              </Col>
            }
          </Row>
        </Container>

      }
    </>
  )
}

export default Cupones
