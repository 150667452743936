import styled from 'styled-components'
import Button from 'react-bootstrap/Button'

export const CustomButton1 =styled(Button)`
  color: ${(props) => props.theme.btn_text_secondary};
  background-color: ${(props) => props.theme.btn_bg_secondary};
  border-color:${(props) => props.theme.btn_border_secondary};
  padding: 4px 24px !important;
  border-radius: 16px !important;
  &:hover{
    color: ${(props) => props.theme.btn_text_secondary};
    background-color: ${(props) => props.theme.btn_hover_secondary};
    border-color: ${(props) => props.theme.btn_hover_secondary};
  }

  &:disabled{
    background-color: ${(props) => props.theme.btn_bg_secondary};
    border-color: ${(props) => props.theme.btn_border_secondary};
    pointer-events: none;
    opacity: .65;
    color: ${(props) => props.theme.btn_text_secondary};
  }
`

export const CustomButton2 =styled(Button)`
  color:${(props) => props.theme.btn_text_primary};
  background-color: ${(props) => props.theme.btn_bg_primary};
  border-color: ${(props) => props.theme.btn_border_primary};
  padding: 4px 24px !important;
  border-radius: 16px !important;
  >div>div{
    background-color:${(props) => props.theme.btn_text_primary};
  }
  &:hover{
    color: ${(props) => props.theme.btn_text_primary};
    background-color: ${(props) => props.theme.btn_hover_primary};
    border-color: ${(props) => props.theme.btn_hover_primary};
  }
  &:disabled{
    background-color: ${(props) => props.theme.btn_bg_primary};
    border-color: ${(props) => props.theme.btn_border_primary};
    pointer-events: none;
    opacity: .65;
    color: ${(props) => props.theme.btn_text_primary};
  }
`
export const ButtonSuccess =styled(Button)`
  color:${(props) => props.theme.btn_text_primary};
  background-color: #008D01;
  border-color: #008D01;
  padding: 0.4rem 2rem !important;
  border-radius: 16px;
  font-size: 14px;
  min-width: 160px;
  &:hover{
    color: ${(props) => props.theme.btn_text_primary};
    background-color: #036303;
    border-color: #036303;
  }
  &:disabled{
    background-color: #008D01;
    border-color:#008D01;
    pointer-events: none;
    opacity: .65;
    color: ${(props) => props.theme.btn_text_primary};
  }
`
export const ButtonError =styled(Button)`
  color:${(props) => props.theme.btn_text_primary};
  background-color: #FF0000;
  border-color: #FF0000;
  padding: 0.4rem 2rem !important;
  border-radius: 16px;
  font-size: 14px;
  min-width: 160px;
  &:hover{
    color: ${(props) => props.theme.btn_text_primary};
    background-color: #CA0A0A;
    border-color: #CA0A0A;
  }
  &:disabled{
    background-color: #FF0000;
    border-color:#FF0000;
    pointer-events: none;
    opacity: .65;
    color: ${(props) => props.theme.btn_text_primary};
  }
`