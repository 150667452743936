import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import useFetch from '../hooks/useFetch'
import {Apis} from '../util/Apis'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Anfora from '../components/templates/anforaTemplate'
import LoadingCard from '../components/generales/loading'
import NoElements from '../components/generales/noElements'
import CustomCard from '../components/generales/card'

import anfora from '../components/icons/anfora.svg'

const IconAnfora = styled.div`
  width: 24px;
  height: 25px;
  background-color: #000;
  -webkit-mask: url(${anfora}) no-repeat center;
  mask: url(${anfora}) no-repeat center;
`

const Anforas = () => {

  const salaInfo = JSON.parse(localStorage.getItem('salaInfo'))

  const {data, loading} = useFetch(Apis.CAPP + '/anforas?salaId=' + salaInfo.salaId)

  const [anforasDisponibles, setAnforasDisponibles] = useState([])

  useEffect(() => {
    if (data !== undefined && data !== null) {
      setAnforasDisponibles(data)
    }
  }, [data])

  return (
    <>
      {loading ?
        <LoadingCard/>
        :
        <Container fluid style={{marginTop: '-4rem', padding: '0 1rem'}}>

          <Row>
            <Col xs={12} sm={12} md={12}>
              <CustomCard titulo='Ánfora'
                subtitulo='lista de ánforas disponibles'
                icono={<IconAnfora style={{marginRight:'4px'}}/>}
                detalle={<span> <strong>{anforasDisponibles.length}</strong> ánfora(s) disponible(s)</span>}
              />
            </Col>

            {anforasDisponibles.length > 0 ?
              <>
                {anforasDisponibles.map((item, i) =>
                  <Col xs={12} sm={6} md={6} key={i}>
                    <Anfora nombre={item.name} depositados={item.quantity} disponibles={item.couponsAvailable}
                            id={item.id} anforaColor={item.couponTypeColor}/>
                  </Col>
                )}
              </>
              :
              <Col xs={12} sm={12} md={12}>
                <NoElements titulo='No hay ánforas disponibles en este momento'
                            subtitulo='Vuelva a intentarlo más tarde'/>
              </Col>
            }
          </Row>

        </Container>
      }
    </>
  )
}


export default Anforas
