import React, {useEffect, useState} from 'react'
import useFetch from '../hooks/useFetch'
import {Apis} from "../util/Apis"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Sorteo from '../components/templates/sorteoTemplate'
import LoadingCard from '../components/generales/loading'
import NoElements from '../components/generales/noElements'
import CustomCard from '../components/generales/card'
import {MdOutlineEditCalendar } from 'react-icons/md'

const Sorteos = () => {

  const salaInfo = JSON.parse(localStorage.getItem('salaInfo'))
  const {data, loading} = useFetch(Apis.SORTEOS + '/sorteos?salaId=' + salaInfo.salaId)
  const [sorteosdelDia, setSorteosdelDia] = useState([])

  useEffect(() => {
    if (data !== undefined && data !== null) {
      setSorteosdelDia(data)
    }
  }, [data])

  return (
    <>
      {loading ?
        <LoadingCard/>
        :
        <Container fluid style={{marginTop: '-4rem', padding: '0 1rem'}}>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <CustomCard titulo='Sorteos'
                subtitulo='Lista de sorteos programados para el dia de hoy'
                icono={<MdOutlineEditCalendar style={{fontSize:'1.5rem',marginRight:'4px'}}/>}
                detalle={<span><strong>{sorteosdelDia.length}</strong> sorteo(s)</span>}
              />
              
            </Col>

            {sorteosdelDia.length > 0 ?
                <Col>
                  
                  
                  <Sorteo datasorteo={sorteosdelDia}/>
                </Col>
              :
              <Col xs={12} sm={12} md={12}>
                <NoElements titulo='No hay sorteos disponibles para el día de hoy'
                            subtitulo='Vuelva a intentarlo más tarde'/>
              </Col>
            }
          </Row>
        </Container>
      }
    </>
  )
}

export default Sorteos
