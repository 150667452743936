const baseIp = 'https://api4.wargos.cloud'
const api5 = 'https://api5.wargos.cloud'

export const Apis = Object.freeze({

  BASE: 'https://api4.wargos.cloud',
  OAUTH: 'https://accounts.wargos.cloud',
  CAPP: `${api5}/xcustomerapp`,
  SORTEOS: `${baseIp}/xsorteos`,
  PC: `${baseIp}/xpointcore`,
});
