import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Col, Container, Form, Row} from 'react-bootstrap'
import NoElements from '../components/generales/noElements'
import {CustomButton2} from '../components/generales/customButton'

import {Swiper, SwiperSlide} from 'swiper/react'
import SwiperCore, {Autoplay, Pagination} from 'swiper'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import CustomCard from '../components/generales/card'
import {MdCampaign} from 'react-icons/md'
import {BsWhatsapp} from 'react-icons/bs'
import useFetch from "../hooks/useFetch";
import {Apis} from "../util/Apis";

SwiperCore.use([Pagination, Autoplay]);

const PromoImg = styled.img`
  box-shadow: 0px 0 10px black;
  border-radius: 5px;
  margin-bottom: 1rem;
`

const PromocionesFormulario = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Promociones = () => {

  const salaInfo = JSON.parse(localStorage.getItem('salaInfo'))
  const [sala, setSala] = useState([]);
  const [salaPublicidad, setSalaPublicidad] = useState({name: '', publicidad: [], sala: salaInfo.salaId})
  const {data} = useFetch(Apis.CAPP + `/ads-banners?salaId=${salaInfo.salaId}`, []);
  const imgUrl = "https://wgt-tmp-uploads.s3.amazonaws.com/"

  const [mensaje, setMensaje] = useState('Hola, quisiera saber más sobre las promociones!');

  useEffect(() => {
    let checkSala = localStorage.getItem("salaInfo")
    if (checkSala !== undefined && checkSala !== null) {
      setSala(JSON.parse(checkSala))
    }
  }, [])

  useEffect(() => {
    if (data) {
      const object = {
        name: salaInfo.name,
        publicidad: data.map(item => item.url),
        salaId: salaInfo.salaId
      }
      setSalaPublicidad(object)
    }
  }, [data])

  const carousel = false

  const fullMensaje = sala.whatsapp + mensaje

  return (
    <Container fluid style={{marginTop: '-4rem', padding: '0 1rem'}}>
      <Row>
        <Col xs={12} sm={12} md={12}>
          <CustomCard titulo='Promociones'
                      icono={<MdCampaign style={{fontSize: '1.5rem', marginRight: '4px'}}/>}
                      subtitulo='Consulte a nuestro asesores sobre nuestras promociones: '
                      cuerpo={
                        <PromocionesFormulario>
                          <Form.Control as="textarea" rows={3} style={{margin: '1rem 0'}}
                                        value={mensaje}
                                        onChange={(e) =>
                                          setMensaje(e.target.value)
                                        }
                          />
                          <CustomButton2 href={fullMensaje} target="_blank" rel="noopener noreferrer">
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                              <BsWhatsapp/>
                              <span style={{marginLeft: '0.3rem'}}>PREGUNTAR</span>
                            </div>
                          </CustomButton2>
                        </PromocionesFormulario>
                      }
          />
        </Col>

        {salaPublicidad.publicidad.length <= 0 ?
          <Col xs={12} sm={12} md={12}>
            <NoElements titulo="No hay promociones disponibles en este momento"
                        subtitulo="Vuelva a consultar mas tarde"/>
          </Col>
          :
          <>
            {carousel ?
              <Col xs={12} sm={12} md={12} style={{marginTop: '2rem'}}>
                <Swiper loop={true}
                        autoplay={{
                          "delay": 2500,
                          "disableOnInteraction": false
                        }}
                        slidesPerView={1}
                        spaceBetween={15}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                        initialSlide={1}
                >
                  {salaPublicidad.publicidad.map((i) => {
                    return <SwiperSlide key={i}><PromoImg src={`${imgUrl}${i}`} alt="img" style={{
                      margin: '0.5rem',
                      width: 'calc(100% - 1rem)'
                    }}/></SwiperSlide>
                  })}
                </Swiper>
              </Col>
              :
              <>
                {salaPublicidad.publicidad.map((item, i) =>
                  <Col xs={12} sm={12} md={6} key={i}>
                    <PromoImg src={`${imgUrl}${item}`} width='100%' alt="promo"/>
                  </Col>
                )}
              </>
            }
          </>
        }
      </Row>
    </Container>
  )
}

export default Promociones
