import React, {useEffect, useReducer, useState} from "react";
import authReducer from "../reducer/authentication.reducer";
import AuthGlobal from "./AuthContext";
import {setCurrentUser} from "../actions/authentication.action";
import jwt_decode from "jwt-decode"

//https://medium.com/@nicomorales297/login-con-mern-stack-jwt-hooks-y-context-api-frontend-1f86fb7e738a
const Auth = props => {

  const initialState = {
    isAuthenticated: null,
    user: {}
  };

  const [show, setShow] = useState(false)

  const [stateUser, dispatch] = useReducer(authReducer, initialState)

  //TODO, podria llamarse en PrivateRoute
  //mejor llamarlo desde aqui para que private route solo chequea un sitio centralizado en memoria
  //esto debe llamarse siempre al cargar la pagina
  //no deberia haber un private route como padre de este componente xq no tendra contexto y al hacer refresh no funcionara
  useEffect(() => {
    //console.log('auth en local: ' + localStorage.auth)
    //console.log('first auth: ' + initialState.isAuthenticated)
    //en caso no encuentre este valor en ls , deslogueamos
    if (localStorage.getItem('salaInfo') === null) {
      //console.log('super nullll')
      localStorage.removeItem('auth')
      dispatch(setCurrentUser(null))
    }

    if (localStorage.auth) {
      const token = localStorage.auth ? localStorage.auth : "";
      dispatch(setCurrentUser(jwt_decode(token)));
    }

    //hack para dar tiempo a que el context se propague
    const timer = setTimeout(() => {
      setShow(true)
    }, 200);
    return () => clearTimeout(timer);
  }, []);

  return (
    <AuthGlobal.Provider
      value={{
        stateUser,
        dispatch
      }}>
      {show && props.children}
    </AuthGlobal.Provider>
  );

};

export default Auth;
