import React, {useEffect} from 'react'
import {Navigate} from "react-router-dom";

const Logout = () => {

  useEffect(()=>{
    localStorage.removeItem('auth')
  },[])

  return (
    <Navigate to={'/login'}/>
  )
}

export default Logout
