import {callApiToSavePermissions, callApiToSaveToken} from "../src/components/login/actions/authentication.action"
import firebase from "firebase/compat";

export const inicializarFirebase = async () => {
  firebase.initializeApp({
    apiKey: "AIzaSyBO4WnwEvJzN3rD_-s_eDxrCJu2K8VETYY",
    authDomain: "notifications-98a57.firebaseapp.com",
    databaseURL: "https://notifications-98a57.firebaseapp.com",
    projectId: "notifications-98a57",
    storageBucket: "notifications-98a57.appspot.com",
    messagingSenderId: "1095381535267",
    appId: "1:1095381535267:web:664088da3e5280a1ea7f96"
  });
};

export const askForPermissions = () => {
  try {
    //const messaging = firebase.messaging();
    Notification.requestPermission().then(async permission=>{
      if (permission === 'denied') {
        console.log('Permission wasn\'t granted. Allow a retry.');
        localStorage.setItem("permissionsFcm", "false")
        callApiToSavePermissions()
        return;
      } else if (permission === 'default') {
        console.log('The permission request was dismissed.');
        localStorage.setItem("permissionsFcm", "false")
        return;
      }
      console.log("se aceptaron los permisos")
      localStorage.setItem("permissionsFcm", "true")
      //getTokenCustomerToSavePermissions()

      getTokenCustomerAndSave();
      return true;
    })


  } catch (error) {
    console.error(error);
  }
};

export const getTokenCustomer = () => {
  //alert("getting token")
  const messaging = firebase.messaging();
  messaging.getToken().then(respuesta =>{
    console.log("user token: ", respuesta);
    localStorage.setItem("tokenFcm", respuesta)
  })
};

export const getTokenCustomerAndSave = () => {

  const messaging = firebase.messaging();
  messaging.getToken().then(respuesta =>{
    console.log("user token: ", respuesta);
    localStorage.setItem("tokenFcm", respuesta)
    if(respuesta.length > 0){
      console.log("saving permissions")
      callApiToSaveToken();
      callApiToSavePermissions();
    }

  })
}

export const getTokenCustomerToSavePermissions = () => {
  const messaging = firebase.messaging();
  messaging.getToken().then(respuesta =>{
    console.log("user token: ", respuesta);
    localStorage.setItem("tokenFcm", respuesta)
    callApiToSavePermissions()
  })
};

export const requestPermissionState = () => {
  //console.log(Notification.permission)
  return Notification.permission;
}
