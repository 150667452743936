import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import {FaTimes} from 'react-icons/fa'

export const MenumobileContainer =styled.div`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 40%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.3s all ease-in-out;
  overflow-y: auto;
  opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
  top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
`
export const PerfilContainer =styled.div`
  display: flex;
  flex-direction: row;
  background: #0A1033;
  padding: 0px 1rem;
  width: 100%;
  min-height: 60px;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
`

export const PerfilLink = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size:1rem;
  transition: 0.2s all ease-in-out;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  list-style: none;
`

export const CloseIcon =styled(FaTimes)`
  color: #fff;
`

export const Icon =styled.div`
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
  transform: translate(-20%,0%);
`

export const MenumobileWrapper =styled.div`
  color: #0A1033;
  background: linear-gradient(180deg, #fff, transparent);
  width: 100%;
  padding: 1rem;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  align-items: center;
`

export const MenumobileLink = styled(NavLink)`
  display: flex;
  color: #0A1033;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width:100%;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 1rem;
  transition: 0.3s all ease-in-out;
  &.active{
    box-shadow: inset 0 -5px #D835FA;
    background-color: #eaeaea;
    color: #0A1033;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    border-radius: 10px 10px 0 0;
    div{
      background-color: #0A1033;
    }
  }
  &:hover{
    box-shadow: inset 0 -5px #D835FA;
    background-color: #eaeaea;
    color: #0A1033;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    border-radius: 10px 10px 0 0;
  }
`
export const MenumobileLabel = styled.span`
  margin-left: 12px;
`
