import React, {useEffect, useState, useContext } from 'react'
import styled from 'styled-components'
import {Col, Container, Form, Row} from 'react-bootstrap'
import useFetch from '../hooks/useFetch'
import {Apis} from "../util/Apis"
import AuthContext from "../components/login/store/AuthContext"
import {logoutUser} from "../components/login/actions/authentication.action"

import man from '../components/icons/man.svg'
import {CustomButton2} from '../components/generales/customButton'
import LoadingCard from '../components/generales/loading'
import { FaUser } from "react-icons/fa";
import CustomCard from '../components/generales/card'

export const IconProfile = styled.div`
  width: 60px;
  height: 64px;
  background-color: ${(props) => props.theme.icon_primary};
  -webkit-mask: url(${man}) no-repeat center;
  mask: url(${man}) no-repeat center;
`

const ContenedorPerfil = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: 0.5s all ease-in-out;
`
const FormLabel = styled.label`
  margin-bottom: 0.3rem;
  margin-top: 1rem;
`

const ContenedorBoton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1rem;
  text-align: center;
`


const Perfil = () => {

  const {data: meData, loading} = useFetch(Apis.CAPP + '/me')

  const [document, setDocument] = useState('')
  const [firstName, setFirstName] = useState(' -- ')
  const [lastName, setLastName] = useState(' -- ')
  const [password, setPassword] = useState('123456')

  const {dispatch} = useContext(AuthContext)

  useEffect(() => {
    if (meData !== undefined && meData !== null) {
      setFirstName(meData.firstName)
      setLastName(meData.lastName)
      setDocument(meData.document)
    }
  }, [meData])

  return (
    <>
      {loading ?
        <LoadingCard/>
        :
        <Container fluid style={{marginTop: '-4rem', padding: '0 1rem'}}>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <CustomCard tipo2 
                titulo='Perfil'
                subtitulo='Informacion básica del cliente'
                icono={<FaUser style={{fontSize:'1.5rem',marginRight:'4px'}} />}
                cuerpo={
                  <ContenedorPerfil>
                    <FormLabel>Nombres</FormLabel>
                    <Form.Control
                      type="text"
                      id="nombres"
                      value={firstName}
                      onChange={(e) =>
                        setFirstName(e.target.value)
                      }
                    />
                    <FormLabel>Apellidos</FormLabel>
                    <Form.Control
                      type="text"
                      id="apellidos"
                      value={lastName}
                      onChange={(e) =>
                        setLastName(e.target.value)
                      }
                    />
                    <FormLabel>Documento</FormLabel>
                    <Form.Control disabled
                      type="text"
                      id="documento"
                      value={document}
                    />

                    <FormLabel>Contraseña</FormLabel>
                    <Form.Control disabled
                      type="password"
                      id="contraseña"
                      value={password}
                      onChange={(e) =>
                        setPassword(e.target.value)
                      }
                    />

                  <ContenedorBoton>
                    <CustomButton2 size="sm" disabled>GUARDAR CAMBIOS</CustomButton2>
                    <CustomButton2 size='sm' onClick={() => logoutUser(dispatch)} style={{marginTop:'1rem'}}>SALIR</CustomButton2>
                  </ContenedorBoton>
                </ContenedorPerfil>
                }
              />
              
            </Col>
          </Row>

         
        </Container>
      }
    </>
  )
}

export default Perfil
