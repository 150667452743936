import React, {useEffect, useState} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'

import MenuMobile from '../components/menumobile'
import Navbar from '../components/navbar'
import Sidebar from '../components/sidebar'
import MainContainer from '../components/contenedor'

import Recompensas from './Recompensas'
import Cupones from "./Cupones";
import Resumen from "./Resumen";
import Puntos from "./Puntos";
import Anforas from "./Anforas";
import DepositarCupones from "./DepositarCupones";
import Sorteos from "./Sorteos";
import Promociones from "./Promociones";
import Notificaciones from "./Notificaciones";
import Contactanos from "./Contactanos";
import Perfil from "./Perfil";
import LectorQR from './LectorQR'
import useFetch from "../hooks/useFetch";
import {Apis} from "../util/Apis";
import PermissionDialog from "../components/permission";
import DetalleCupones from './DetalleCupones'

const Main = () => {

  const [isOpen, setIsOpen] = useState(false)
  const [isOpenDialog, setIsOpenDialog] = useState(false)
  const {data: meData} = useFetch(Apis.CAPP + '/me')

  const toggle = () => {
    setIsOpen(!isOpen)
  }


  const togglePermission = () => {
    setIsOpenDialog(!isOpenDialog)
  }

  useEffect(() => {
    if (meData !== undefined && meData !== null) {
      //setFirstName(meData.notificationsAccepted)
      if (meData.notificationsAccepted === null) {
        setIsOpenDialog(true)
      }
    }
  }, [meData])

  return (
    <React.Fragment>
      <MenuMobile isOpen={isOpen} toggle={toggle}/>
      <PermissionDialog isOpen={isOpenDialog} toggle={togglePermission} setIsOpenDi={setIsOpenDialog}/>
      <Navbar toggle={toggle}/>
      <Sidebar/>
      <MainContainer>
        <Routes>
          <Route path="resumen" element={<Resumen/>} name='Resumen'/>
          <Route path="puntos" element={<Puntos/>} name='Puntos'/>
          <Route path="anforas" element={<Anforas/>} name="Anforas"/>
          <Route path="depositar-cupones/:id" element={<DepositarCupones/>}/>
          <Route path="sorteos" element={<Sorteos/>}/>
          <Route path="promociones" element={<Promociones/>}/>
          <Route path="notificaciones" element={<Notificaciones/>}/>
          <Route path="lector-qr" element={<LectorQR/>}/>
          <Route path='contactanos' element={<Contactanos/>}/>
          <Route path='recompensas' element={<Recompensas/>}/>
          <Route path="perfil" element={<Perfil/>}/>
          <Route path="cupones" element={<Cupones/>}/>
          <Route path="home" element={<Resumen/>}/>
          <Route path="detalle-cupones/:id" element={<DetalleCupones/>}/>
          <Route path="" element={<Navigate to={'/home'}/>}/>
        </Routes>
      </MainContainer>
    </React.Fragment>
  )
}

export default Main
