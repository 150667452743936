import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import 'bootstrap/dist/css/bootstrap.min.css';
import {datadogRum} from "@datadog/browser-rum";
import {inicializarFirebase} from './firebaseConfig'

datadogRum.init({
  applicationId: 'faf7e87b-3c7e-48b6-82b5-bde3b53fdb73',
  clientToken: 'pub76ffd20233451574d046f58afe2fab16',
  site: 'datadoghq.com',
  service:'app-club-3',
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingOrigins: [
    "https://app.dreamsclub.pe",
    "https://app.dreamsclub.com.pe",
    "https://salaqueens.com",
    "https://queenstest.wargos.cloud",
    "https://api4.wargos.cloud"
  ]
});

datadogRum.startSessionReplayRecording();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
inicializarFirebase();
