import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

import anfora from '../icons/anfora.svg'

export const SidebarNav = styled.aside`
  background: #fff;
  width: 230px;
  height: 100vh;
  display: flex;
  z-index: 12;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  transition: 0.5s all ease-in-out;
  z-index: 10;
  box-shadow:0 0.125rem 9.375rem rgb(90 97 105 / 10%), 0 0.25rem 0.5rem rgb(90 97 105 / 12%), 0 0.9375rem 1.375rem rgb(90 97 105 / 10%), 0 0.4375rem 2.1875rem rgb(165 182 201 / 10%);

  @media screen and (max-width: 992px) {
    transition: 0.5s all ease-in-out;
    transform: translateX(-230px);
  }
`
export const SidebarLogo = styled.div`
  background-color: #fff;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e1e5eb ;
`
export const LogoLink = styled(NavLink)`
  cursor:pointer;
  text-decoration: none;
`

export const SidebarWrap = styled.div`
  width: 100%;
  padding-top: 1rem;
  overflow-y: auto;
`

export const SidebarLink = styled(NavLink)`
  display: flex;
  color: ${(props) => props.theme.text_primary};
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 1rem;

  &:hover {
    box-shadow: inset 5px 0 0 ${(props) => props.theme.select_item_primary};
    background-color: ${(props) => props.theme.bg_secondary_hover};
    color: ${(props) => props.theme.select_item_primary};
    cursor: pointer;
    div{
      background-color: ${(props) => props.theme.select_item_primary};
    }
  }
  &.active {
    box-shadow: inset 5px 0 0 ${(props) => props.theme.select_item_primary};
    background-color: ${(props) => props.theme.bg_secondary_hover};
    color: ${(props) => props.theme.select_item_primary};
    div{
      background-color: ${(props) => props.theme.select_item_primary};
    }
  }
`

export const SidebarLabel = styled.span`
  margin-left: 12px;
`

export const IconAnfora = styled.div`
  width:30px;
  height:32px;
  background-color: ${(props) => props.theme.text_primary};
  -webkit-mask: url(${anfora}) no-repeat center;
  mask: url(${anfora}) no-repeat center;
`
