import React from 'react'
import styled from 'styled-components'

const NoElementsCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 3px 10px rgb(0 0 0 / 40%);
  color: ${(props) => props.theme.text_primary};
  border-radius: 10px;
  background: #fff;
  padding: 1.5rem 1rem;
  transition: 0.5s all ease-in-out;
  margin-bottom: 1rem;
  text-align: center;
  h5{
    font-weight:bold;
  }
`

const NoElements = ({titulo, subtitulo}) => {
  return (
    <NoElementsCard>
      <h5>{titulo}</h5>
      <span>{subtitulo}</span>
    </NoElementsCard>
  )
}

export default NoElements
