import React, { useState,useEffect } from 'react'
import { useNavigate,useParams } from 'react-router'
import useFetch from '../hooks/useFetch'
import {Apis} from "../util/Apis"
import dateFormat from 'dateformat'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { TablaDetalle } from './Resumen'
import DetalleCuponesTemplate from '../components/templates/detalleCuponesTemplate'
import { CustomButton2 } from '../components/generales/customButton'
import CustomCard from '../components/generales/card'

import { MdHistory, MdLocalPlay } from 'react-icons/md'
import LoadingCard from '../components/generales/loading'

const DetalleCupones = () => {

  const navigate = useNavigate()
  const salaInfo = JSON.parse(localStorage.getItem('salaInfo'))

  const {id} = useParams()

  const [cuponesHistory, setCuponesHistory] = useState([])
  const [cuponSelect, setCuponSelect] = useState({})

  const {data: dataCupones, loading: loadingCupones} = useFetch(Apis.CAPP + '/point-coupons?salaId=' + salaInfo.salaId)
  const {data: dataHistory, loading: loadingHistory} = useFetch(Apis.CAPP + '/coupons/history')

  useEffect(() => {
    if (dataCupones !== undefined && dataCupones !== null) {
      setCuponSelect(dataCupones.coupons.filter(item => item.id === JSON.parse(id))[0])
    }
  }, [dataCupones])

  useEffect(() => {
    if (dataHistory !== undefined && dataHistory !== null) {
      setCuponesHistory(dataHistory)
    }
  }, [dataHistory])
  
  function regresarCupones() {
    navigate("/cupones");
  }


  return (

    <>
    {loadingCupones ?
      
      <LoadingCard />
      :
      <Container fluid style={{marginTop: '-4rem', padding: '0 1rem'}}>

        <Row>
          <Col xs={12} sm={12} md={12}>
            <CustomCard titulo={cuponSelect.couponName}
              subtitulo='cupon físico'
              icono={<MdLocalPlay style={{fontSize:'1.5rem',marginRight:'4px'}}/>}
              detalle={
                <div style={{display:'flex',flexDirection:'column',alignItems: 'flex-end'}}>
                  <span><strong>{cuponSelect.quantity - cuponSelect.available}</strong> cupon(es) impreso(s)</span>
                  <span style={{fontSize:'14px',color:'#00000080'}}> {cuponSelect.available} cupon(es) sin imprimir</span>
                </div>
              }
            />

            <CustomCard titulo='Detalle de cupones'
              subtitulo='Ultimos cupones obtenidos'
              icono={<MdHistory style={{fontSize:'1.5rem',marginRight:'4px'}}/>}
              cuerpo={
                <TablaDetalle>
                  {cuponesHistory.map((item, i) =>
                    <DetalleCuponesTemplate key={i} maquina={item.machine} fecha={dateFormat(item.createdAt, "yyyy-mm-dd hh:MM TT")} cupones={item.quantity} />
                  )}
                  <CustomButton2 size="sm" onClick={regresarCupones}>REGRESAR</CustomButton2>
                </TablaDetalle>
              }
            />
          </Col>
        </Row>
      </Container>
    }
    </>
  )
}

export default DetalleCupones
