import React, {useContext, useEffect, useState} from 'react'
import './App.css'
import {BrowserRouter, Navigate, Route, Routes, useLocation} from 'react-router-dom'
import axios from "axios"
import Auth from './components/login/store/Auth'
import {ThemeProvider} from 'styled-components'
import Signup from './components/login/signup'
import Login from './components/login/login'
import Logout from './components/login/logout'
import {dreamsColor, GlobalStyles, wargosColor, megaGamingColor,latinColor,stopColor} from './theme'
import Bridge from "./pages/Bridge";
import AuthContext from "./components/login/store/AuthContext";
import Main from "./pages/main";
import {Apis} from "./util/Apis";
import {inicializarFirebase} from "./firebaseConfig";
import MagicLink from "./components/login/magicLink";

// Set the AUTH token for any request
axios.interceptors.request.use(function (config) {
  config.headers.site = window.location.host
  const token = localStorage.getItem('auth');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config;
});

axios.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  const originalConfig = error.config;
  if (error.response) {
    if (error.response.status === 401 && originalConfig.url === Apis.OAUTH + '/api/auth/refreshtoken') {
      window.location = '/logout';
      localStorage.removeItem('auth')
      return Promise.reject(error);
    }

    if (error.response.status === 401) {
      const rs = await axios.post(Apis.OAUTH + "/api/auth/refreshtoken", {
        refresh_token: localStorage.getItem("wsrf"),
        client_id: process.env.REACT_APP_AUTH_CLIENT_ID
      });
      const {accessToken} = rs.data;
      localStorage.setItem("auth", accessToken)
      return axios(originalConfig);
    } else {
      return Promise.reject(error);
    }
  } else {
    return Promise.reject(error);
  }
});

function RequireAuth({children, redirectTo}) {
  const {stateUser} = useContext(AuthContext)
  const {pathname, search} = useLocation();

  const isAuthenticated = stateUser.isAuthenticated
  //console.log('verificando auth : ' + isAuthenticated)
  //console.log(location.pathname)

  //const refered = '/lector-qr?code=Product_id:1005'
  const refered = pathname + search

  let completeRedirect = redirectTo
  if (refered && refered !== '') {
    completeRedirect = redirectTo + '?redirect=' + refered
  }

  return isAuthenticated ? children : <Navigate to={completeRedirect}/>;
}

const App = () => {

  const [siteInfo, setSiteInfo] = useState(null)

  useEffect(() => {
    inicializarFirebase();
    getSalas()
  }, [])

  function getSalas() {
    axios.get(Apis.CAPP + '/site')
      .then(function (response) {
        setSiteInfo(response.data)
      })
      .catch(function (error) {
      })
  }

  function getTheme(siteId){
    let customTheme

    switch (siteId) {
      case 1:
        customTheme = wargosColor
        break;
      case 2:
        customTheme = dreamsColor
        break;
      case 3:
        customTheme = megaGamingColor
        break;
      case 4:
        customTheme = latinColor
        break;
      case 5:
        customTheme = latinColor
        break;
      case 6:
        customTheme = stopColor
        break;
      default:
        customTheme = wargosColor
        break;
    }

    return customTheme
  }

  return (
    <>
      {
        siteInfo && <ThemeProvider theme={getTheme(siteInfo.id)}>
          <GlobalStyles/>
          <BrowserRouter>
            <Auth>
              <Routes>
                <Route path="/*"
                       element={
                         <RequireAuth redirectTo="/login">
                           <Main/>
                         </RequireAuth>
                       }
                />
                <Route path='/bridge' element={<Bridge/>}/>
                <Route path="/sign-up/:id" element={<Signup/>} name="registro"/>
                <Route path="/logout" element={<Logout/>} name="logout"/>
                <Route path="/login" element={<Login/>} name="login"/>
                <Route path="/magic-link/:code" element={<MagicLink/>} name="magic-link"/>
                {/*<Route path="" element={<Navigate to={'/main'}/>}  exact/>*/}
              </Routes>
            </Auth>
          </BrowserRouter>
        </ThemeProvider>
      }
    </>
  )
}

export default App
