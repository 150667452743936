import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import useFetch from '../hooks/useFetch'
import {Apis} from "../util/Apis"
import {useNavigate, useParams} from 'react-router'
import axios from 'axios'
import {useForm} from "react-hook-form"

import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {CustomButton1, CustomButton2} from '../components/generales/customButton'
import LoadingCard from '../components/generales/loading'
import CustomCard from '../components/generales/card'

import { MdLocalPlay } from 'react-icons/md'

const CustomForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
`

const ContenedorButton = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  transition: 0.5s all ease-in-out;

  button {
    margin-bottom: 0.5rem;
  }
`

const TextError = styled.span`
  margin: 10px 0px 20px;
  font-weight: normal;
  color: ${(props) => props.theme.text_default};
  text-align: center;
  font-size: 14px;
`
const ContenedorInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5rem;
  transition: 0.5s all ease-in-out;

  @media screen and (max-width: 425px) {
    flex-direction: column;
    transition: 0.5s all ease-in-out;
  }
`

const DepositarCupones = () => {

  const navigate = useNavigate();
  const {id} = useParams()
  const {salaId} = JSON.parse(localStorage.getItem('salaInfo'))

  const {register, handleSubmit, errors} = useForm();

  const [couponTypeId, setCouponTypeId] = useState(0)
  const [infoAnforas, setInfoAnforas] = useState([])
  const [error, setError] = useState('')
  const [loadingPost, setLoadingPost] = useState(false)


  const {data, loading} = useFetch(Apis.CAPP + '/anforas/' + id + '?salaId=' + salaId)

  useEffect(() => {
    if (data !== undefined && data !== null) {
      setInfoAnforas(data)
      setCouponTypeId(data.couponTypeId)
    }
  }, [data])

  const participarSorteos = (params) => {
    setLoadingPost(true)
    setError('')
    params.anforaId = id
    params.couponTypeId = couponTypeId
    params.salaId = salaId

    axios.post(Apis.CAPP + '/sorteos/play', params).then(res => {
      navigate('/anforas');
      setLoadingPost(false)
    }).catch(err => {
      setError(err.response.data.message)
      setLoadingPost(false)
    })
  }

  function goToAnfora() {
    navigate("/anforas");
  }

  return (
    <>
      {loading ?
        <LoadingCard/>
        :
        <Container fluid style={{marginTop: '-4rem', padding: '0 1rem'}}>

          <Row>
            <Col xs={12} sm={12} md={12}>
              <CustomCard titulo={infoAnforas.name}
                subtitulo={infoAnforas.quantity+' cupones depositados'}
                icono={<MdLocalPlay style={{fontSize:'1.5rem',marginRight:'4px'}}/>}
                detalle={<span> <strong>{infoAnforas.couponsAvailable}</strong> cupon(es) disponible(s)</span>}
              />

              <CustomCard titulo='Ingrese un numero de cupones:'
                cuerpo={
                  <CustomForm>
                    <ContenedorInput>
                      <Form.Control type="number" placeholder="0"
                        style={{minWidth: '100px', maxWidth: '150px', textAlign: 'center'}} autoFocus
                        autoComplete="off"
                        required
                        name={"quantity"}
                        ref={register({
                          required: "Ingrese una cantidad",
                          min: {value: 1, message: "Debe ingresar una cantidad mayor a 0"},
                          max: {value: 1000, message: "Debe ingresar una cantidad menor a 1000"}
                        })}
                      />
                    </ContenedorInput>

                    {errors && <TextError>{errors.quantity?.message}</TextError>}

                    <ContenedorButton>
                      <CustomButton1 size='sm' onClick={goToAnfora}>REGRESAR</CustomButton1>
                      <CustomButton2 size='sm' onClick={handleSubmit(participarSorteos)} className='mx-2'
                                     disabled={loadingPost}>
                        {loadingPost ? 'CARGANDO' : 'DEPOSITAR'}</CustomButton2>
                    </ContenedorButton>

                    {error && <TextError>{error}</TextError>}
                  </CustomForm>
                }
              />
            </Col>
          </Row>


        </Container>
      }
    </>
  )
}

export default DepositarCupones
